import { reverseDictionary } from 'asu-sim-toolkit';

import { CapiCameraPosition } from '../capi';
import { Mapper } from '../utils';
import { CameraPosition } from './domain';

const cameraPositionDictionary: Record<CapiCameraPosition, CameraPosition> = {
  [CapiCameraPosition.Orbit]: CameraPosition.Orbit,
  [CapiCameraPosition.Earth]: CameraPosition.Earth,
};

const reverseCameraPositionDictionary = reverseDictionary(
  cameraPositionDictionary
);

export const mapCameraPositionFromCapi: Mapper<
  CapiCameraPosition,
  CameraPosition
> = (input) => cameraPositionDictionary[input];

export const mapCameraPositionToCapi: Mapper<
  CameraPosition,
  CapiCameraPosition
> = (input) => reverseCameraPositionDictionary[input];
