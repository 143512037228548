import { action, makeObservable, observable } from 'mobx';

import { ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import {
  CapiBoundStore,
  ControlStore,
  ICAPI,
  IControlState,
} from 'asu-sim-toolkit';
import { ViewType } from './domain';
import { ITab } from '../components/TabBar/types';

export class AppStore extends CapiBoundStore<ICapiModel> implements IAppStore {
  private readonly rootStore: IRootStore;
  activeTabId: ViewType = ViewType.Orbit;

  orbitTabState: IControlState;
  chartTabState: IControlState;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.rootStore = rootStore;

    this.orbitTabState = new ControlStore(
      capi,
      'Sim.Orbit.Tab.Enabled',
      'Sim.Orbit.Tab.Visible'
    );

    this.chartTabState = new ControlStore(
      capi,
      'Sim.Chart.Tab.Enabled',
      'Sim.Chart.Tab.Visible'
    );

    makeObservable(this, {
      activeTabId: observable,
      setActiveTab: action.bound,
    });

    this.bindToCapi('activeTabId', 'Sim.View');
  }

  setActiveTab(tab: ITab<ViewType>): void {
    this.activeTabId = tab.id;
  }
}
