import * as THREE from 'three';
import { scaleLinear } from 'd3-scale';
import { ECCENTRICITY, OBLIQUITY, PRECESSION } from '../../stores/domain';

export const obliquityRange = scaleLinear()
  .domain([OBLIQUITY.min, OBLIQUITY.max])
  .range([OBLIQUITY.minRad, OBLIQUITY.maxRad]);

export const precessionRange = scaleLinear()
  .domain([PRECESSION.min, PRECESSION.max])
  .range([PRECESSION.minRad, PRECESSION.maxRad]);

export const aphelionRange = scaleLinear()
  .domain([ECCENTRICITY.min, ECCENTRICITY.max])
  .range([ECCENTRICITY.middle, ECCENTRICITY.aphelion]);

export const perihelionRange = scaleLinear()
  .domain([ECCENTRICITY.min, ECCENTRICITY.max])
  .range([ECCENTRICITY.middle, ECCENTRICITY.perihelion]);

export const getEarthCameraSettings = (coords: THREE.Vector3) => ({
  target: coords,
  position: new THREE.Vector3(coords.x + 6, coords.y + 8, coords.z + 10),
  zoom: 2,
  minDistance: 6,
  maxDistance: 10,
});

export const getOrbitCameraSettings = (coords: THREE.Vector3) => ({
  target: coords,
  position: new THREE.Vector3(coords.x + 1, coords.y + 12, coords.z + 1.3),
  zoom: 0.5,
  minDistance: 5,
  maxDistance: 9,
});

type SphereArgs = [
  radius?: number,
  widthSegments?: number,
  heightSegments?: number,
  phiStart?: number,
  phiLength?: number,
  thetaStart?: number,
  thetaLength?: number
];

export const getSphereArgs = (
  radius: number,
  phiStart?: number,
  phiLength?: number,
  thetaStart?: number,
  thetaLength?: number
): SphereArgs => [
  radius,
  180,
  180,
  phiStart,
  phiLength,
  thetaStart,
  thetaLength,
];
