/* eslint react/no-unknown-property: "off" */
import React, { FC } from 'react';
import * as THREE from 'three';

import { SpriteText } from '../../UI/SpriteText';
import { EARTH_RADIUS } from '../config';
import { useEarthReadouts } from './use-earth-readouts';

interface IEarthGeometricSet {
  obliquity: number;
  precession: number;
}

export const EarthGeometricSet: FC<IEarthGeometricSet> = ({
  obliquity,
  precession,
}) => {
  const geometryRadius = EARTH_RADIUS * 1.8;

  const {
    axisGeometry,
    precessionGeometry,
    precessionMatrix,
    obliquityGeometry,
    obliquityMatrix,
    halfAxisGeometry,
  } = useEarthReadouts(geometryRadius, obliquity, precession);

  return (
    <>
      {/* Vertical axis */}
      <line_
        geometry={axisGeometry}
        onUpdate={(line) => line.computeLineDistances()}
      >
        <lineDashedMaterial
          dashSize={0.075}
          gapSize={0.075}
          color="gray"
          linewidth={1}
        />
      </line_>
      {/* Precession */}
      <line_
        geometry={precessionGeometry}
        onUpdate={(line) => line.computeLineDistances()}
      >
        <lineDashedMaterial
          dashSize={0.03}
          gapSize={0.03}
          color="green"
          linewidth={1}
        />
      </line_>
      <group rotation={precessionMatrix}>
        {/* Obliquity */}
        <line_
          geometry={obliquityGeometry}
          onUpdate={(line) => line.computeLineDistances()}
        >
          <lineDashedMaterial
            dashSize={0.03}
            gapSize={0.03}
            color="yellow"
            linewidth={1}
          />
        </line_>
        <group rotation={obliquityMatrix}>
          <line_ geometry={halfAxisGeometry}>
            <lineBasicMaterial color="red" linewidth={1} />
          </line_>
          <line_ geometry={halfAxisGeometry} position={[0, -geometryRadius, 0]}>
            <lineBasicMaterial color="deepskyblue" linewidth={1} />
          </line_>

          <SpriteText
            position={new THREE.Vector3(0, geometryRadius, 0)}
            anchorY="bottom"
            fontSize={0.2}
            color="red"
          >
            N
          </SpriteText>

          <SpriteText
            position={new THREE.Vector3(0, -geometryRadius, 0)}
            anchorY="top"
            fontSize={0.2}
            color="deepskyblue"
          >
            S
          </SpriteText>
        </group>
      </group>
    </>
  );
};
