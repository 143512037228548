/* eslint-disable react/no-unknown-property,@typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react';
import { Group, MathUtils, Vector2 } from 'three';
import { useFrame, Camera, useThree } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import { scaleLinear } from 'd3-scale';

import { clamp } from '../../utils';
import { ICameraSettings, ZOOM } from '../../stores/domain';
import { MIN_INCLINATION_ANGLE, MAX_INCLINATION_ANGLE } from './config';

const { degToRad, radToDeg } = MathUtils;

let adjustedCameraDistance = 10;

interface IOrbitalCameraProps {
  cameraSettings: ICameraSettings;
  inclinationAngle: number;
  zoom: number;

  onCameraInclinationChange(angle: number): void;
  onZoomChange(value: number): void;
}

export const OrbitalCamera = ({
  cameraSettings: {
    targetRef,
    distance,
    minDistance,
    maxDistance,
    fov,
    xRotation,
  },
  onCameraInclinationChange,
  inclinationAngle,
  zoom,
  onZoomChange,
}: IOrbitalCameraProps) => {
  const [isPointerDown, setIsPointerDown] = useState(false);
  const prevPointer = useRef<Vector2 | null>(null);

  const cameraRef = useRef<Camera>(null);
  const cameraAxisXGroupRef = useRef<Group>(null);
  const cameraAxisYGroupRef = useRef<Group>(null);

  const zoomRange = scaleLinear()
    .domain([minDistance, distance, maxDistance])
    .rangeRound([ZOOM.max, ZOOM.default, ZOOM.min]);

  const {
    gl: { domElement },
  } = useThree();

  useEffect(() => {
    adjustedCameraDistance = zoomRange.invert(zoom);
  }, [zoom, zoomRange]);

  useEffect(() => {
    const yGroup = cameraAxisYGroupRef.current;

    if (yGroup) {
      yGroup.rotation.set(-degToRad(inclinationAngle), 0, 0);
    }
  }, [inclinationAngle]);

  useEffect(() => {
    const xGroup = cameraAxisXGroupRef.current;
    const yGroup = cameraAxisYGroupRef.current;

    if (xGroup && yGroup) {
      adjustedCameraDistance = clamp(distance, minDistance, maxDistance);
      xGroup.attach(yGroup!);
      yGroup.attach(cameraRef.current!);
      xGroup.rotation.set(0, degToRad(xRotation), 0);
      yGroup.rotation.set(-degToRad(inclinationAngle), 0, 0);
      cameraRef.current?.position.set(0, 0, adjustedCameraDistance);
    }

    targetRef.current?.attach(xGroup);
    xGroup?.position.set(0, 0, 0);

    cameraRef.current?.lookAt(targetRef.current.position);

    const pointerDownHandler = () => {
      setIsPointerDown(true);
      prevPointer.current = null;
    };

    const pointerUpHandler = () => {
      setIsPointerDown(false);
    };

    const pointerWheelHandler = (ev: WheelEvent) => {
      ev.preventDefault();
      adjustedCameraDistance = clamp(
        (adjustedCameraDistance += ev.deltaY / 10),
        minDistance,
        maxDistance
      );
      onZoomChange(zoomRange(adjustedCameraDistance));
    };

    domElement.addEventListener('pointerdown', pointerDownHandler);
    domElement.addEventListener('pointerup', pointerUpHandler);
    domElement.addEventListener('wheel', pointerWheelHandler);

    return () => {
      domElement.removeEventListener('pointerdown', pointerDownHandler);
      domElement.removeEventListener('pointerup', pointerUpHandler);
      domElement.removeEventListener('wheel', pointerWheelHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef.current]);

  useFrame(({ pointer }) => {
    if (cameraRef.current) {
      cameraRef.current.position.z = adjustedCameraDistance;
    }

    if (isPointerDown) {
      const xGroup = cameraAxisXGroupRef.current;
      const yGroup = cameraAxisYGroupRef.current;

      if (prevPointer.current === null) prevPointer.current = pointer.clone();
      if (!yGroup || !xGroup) return;

      const deltaX = degToRad((pointer.x - prevPointer.current.x) * -150);
      const deltaY = degToRad((pointer.y - prevPointer.current.y) * 100);

      const minInclinationRad = -degToRad(MIN_INCLINATION_ANGLE);
      const maxInclinationRad = -degToRad(MAX_INCLINATION_ANGLE);

      prevPointer.current.x = pointer.x;
      prevPointer.current.y = pointer.y;

      xGroup.rotateY(deltaX);

      if (yGroup.rotation.x + deltaY > minInclinationRad) {
        yGroup.rotation.set(minInclinationRad, 0, 0);
        onCameraInclinationChange(MIN_INCLINATION_ANGLE);
        return;
      }
      if (yGroup.rotation.x + deltaY < maxInclinationRad) {
        yGroup.rotation.set(maxInclinationRad, 0, 0);
        onCameraInclinationChange(MAX_INCLINATION_ANGLE);
        return;
      }

      yGroup.rotateX(deltaY);
      onCameraInclinationChange(-radToDeg(yGroup.rotation.x));
    }
  });

  return (
    <group ref={cameraAxisXGroupRef}>
      <group ref={cameraAxisYGroupRef}>
        <PerspectiveCamera ref={cameraRef} makeDefault fov={fov} />
      </group>
    </group>
  );
};
