import React from 'react';
import classNames from 'classnames';

import { ITab } from './types';
import { Icon } from '../Icon';

export interface ITabBarItemProps<T> {
  tab: ITab<T>;
  isActive: boolean;

  onClick(tab: ITab<T>): void;
}

export function TabBarItem<T>({ tab, onClick, isActive }: ITabBarItemProps<T>) {
  return (
    <div
      className={classNames(
        'w-full rounded-t-md gap-sm',
        isActive && 'bg-blue shadow-layout-top'
      )}
    >
      <button
        type="button"
        onClick={() => onClick(tab)}
        className="w-full py-[6px] flex items-center justify-center text-lg font-title font-bold disabled:opacity-50 focus:outline-none rounded-t-md border-2 border-transparent focus:border-2 focus:border-outline"
        disabled={!tab.isEnabled}
      >
        {tab.iconId && <Icon id={tab.iconId} className="mr-3 text-xl" />}
        <span>{tab.label}</span>
      </button>
      <div
        className={classNames(
          'w-[96%] m-auto h-[1px] opacity-60',
          isActive && 'bg-blue-light'
        )}
      />
    </div>
  );
}
