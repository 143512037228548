import { useMemo } from 'react';
import { BufferGeometry, EllipseCurve, Euler, MathUtils, Vector3 } from 'three';
import { calculateAnglePoints, lawOfCosines } from '../../../utils';

export function useEarthReadouts(
  readoutRadius: number,
  obliquity: number,
  precession: number
) {
  return useMemo(() => {
    const obliquityMatrix = new Euler(MathUtils.degToRad(obliquity), 0, 0);

    const precessionMatrix = new Euler(
      0,
      MathUtils.degToRad(precession + 90),
      0
    );

    const axisPoints = [
      new Vector3(0, readoutRadius, 0),
      new Vector3(0, -readoutRadius, 0),
    ];
    const halfAxisPoints = [
      new Vector3(0, readoutRadius, 0),
      new Vector3(0, 0, 0),
    ];

    const axisGeometry = new BufferGeometry().setFromPoints(axisPoints);
    const halfAxisGeometry = new BufferGeometry().setFromPoints(halfAxisPoints);

    const obliquityPoints = new EllipseCurve(
      0,
      0,
      readoutRadius,
      readoutRadius,
      MathUtils.degToRad(90 - obliquity),
      MathUtils.degToRad(90),
      false,
      0
    )
      .getPoints(50)
      .map((p) => new Vector3(0, p.y, p.x));

    const obliquityGeometry = new BufferGeometry().setFromPoints(
      obliquityPoints
    );

    const precessionPoints = calculateAnglePoints({
      radius: lawOfCosines(
        halfAxisPoints[0].applyEuler(obliquityMatrix).y,
        readoutRadius,
        MathUtils.degToRad(obliquity)
      ),
      angle: -MathUtils.degToRad(precession),
      axis: 'x',
      p0: axisPoints[0].applyEuler(obliquityMatrix).y,
    });

    const precessionGeometry = new BufferGeometry().setFromPoints(
      precessionPoints
    );

    return {
      precessionGeometry,
      obliquityGeometry,
      obliquityMatrix,
      precessionMatrix,
      axisGeometry,
      halfAxisGeometry,
      axisPoints,
    };
  }, [readoutRadius, obliquity, precession]);
}
