import React, { FC, ReactNode } from 'react';
import { ITabProps } from 'asu-sim-toolkit';
import classNames from 'classnames';

export interface IButtonProps extends ITabProps {
  children: ReactNode;
  autoFocus?: boolean;
  className?: string;
  activeClassName?: string;
  disabled?: boolean;
  isActive?: boolean;

  onClick(): void;
}

export const Button: FC<IButtonProps> = ({
  children,
  onClick,
  autoFocus = false,
  className,
  activeClassName,
  inputRef,
  onKeyDown,
  disabled = false,
  isActive = false,
}) => (
  <button
    autoFocus={autoFocus}
    disabled={disabled}
    className={classNames(
      'focus:outline-none focus:ring-2 focus:ring-outline rounded-md',
      isActive && (activeClassName || 'border-outline'),
      disabled && 'opacity-50 cursor-not-allowed',
      className
    )}
    type="button"
    onClick={onClick}
    ref={inputRef}
    onKeyDown={onKeyDown}
  >
    {children}
  </button>
);
