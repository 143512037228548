import { MutableRefObject } from 'react';
import {
  DEFAULT_INCLINATION_ANGLE,
  INCLINATION_ANGLE_OFFSET,
  MAX_INCLINATION_ANGLE,
  MIN_INCLINATION_ANGLE,
} from '../components/Orbit/config';

export enum ViewType {
  Orbit = 'orbit',
  Chart = 'chart',
}

export enum CameraPosition {
  Orbit,
  Earth,
}

export enum CameraView {
  Side = 'side',
  Top = 'top',
  Custom = 'custom',
}

export enum InclinationAngle {
  Side = MIN_INCLINATION_ANGLE,
  Top = MAX_INCLINATION_ANGLE,
  Default = DEFAULT_INCLINATION_ANGLE,
  Offset = INCLINATION_ANGLE_OFFSET,
}

export enum EarthPosition {
  January = 0,
  April = 1,
  July = 2,
  October = 3,
}

export interface ICameraSettings {
  targetRef: MutableRefObject<any>;
  distance: number;
  minDistance: number;
  maxDistance: number;
  fov: number;
  xRotation: number;
}

export interface IChartDataPoint {
  year: number;
  eccentricity: number;
  obliquity: number;
  perihelion: number;
  tempAnomaly: number;
  precession: number;
}

export interface IMergedDataPoint {
  year: number;
  tempAnomaly: number;
  value: number;
}

export const ECCENTRICITY = {
  id: 'eccentricity',
  min: 0.005,
  max: 0.06,
  step: 0.001,
  perihelion: 141,
  aphelion: 159,
  middle: 150,
};

export const OBLIQUITY = {
  id: 'obliquity',
  min: 22.1,
  max: 24.5,
  step: 0.1,
  minRad: (22.1 * Math.PI) / 180,
  maxRad: (24.5 * Math.PI) / 180,
};

export const PRECESSION = {
  id: 'precession',
  min: 0,
  max: 360,
  step: 1,
  minRad: 0,
  maxRad: 2 * Math.PI,
};

export const TEMP_CHANGE = {
  id: 'tempChange',
};

export const INSOLATION = {
  min: 97,
  max: 577,
};

export enum EarthPositionConfig {
  apsis = 'apsis',
  withMidPoints = 'withMidPoints',
}

export const EARTH_POSITION_CONFIG = {
  apsis: {
    min: EarthPosition.January,
    max: EarthPosition.July,
    step: 2,
    customMin: 'Jan',
    customMax: 'Jul',
    textPerValues: [
      { value: 0, text: 'January (Perihelion)' },
      { value: 2, text: 'July (Aphelion)' },
    ],
  },
  withMidPoints: {
    min: EarthPosition.January,
    max: EarthPosition.October,
    step: 1,
    customMin: 'Jan',
    customMax: 'Oct',
    textPerValues: [
      { value: 0, text: 'January (Perihelion)' },
      { value: 1, text: 'April' },
      { value: 2, text: 'July (Aphelion)' },
      { value: 3, text: 'October' },
    ],
  },
};

export const EARTH_POSITION = {
  configs: EARTH_POSITION_CONFIG,
  perihelion: EarthPosition.January,
  aphelion: EarthPosition.July,
};

export const ORBIT = {
  points: 360,
  /** Determines the shift of the earth in its orbit. At the moment we may have 4 earth positions that are equally distant from each other. */
  shift: 90,
};

// Thousands of years ago
export const YEAR_RANGE = {
  min: 800,
  max: 5,
  divider: 1000,
};

export const ZOOM = {
  min: 25,
  max: 150,
  default: 100,
  tick: 25,
};

// NOTE: Technically, this could just be `keyof IChartDataPoint`, but
//       for in some scenarios (like mapping) it's nice to be able to
//       iterate through these, so - enum it is.
export enum SimParameter {
  eccentricity = 'eccentricity',
  obliquity = 'obliquity',
  precession = 'precession',
  tempAnomaly = 'tempAnomaly',
}
