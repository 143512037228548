import React from 'react';

import { ITab } from './types';
import { TabBarItem } from './TabBarItem';

export interface ITabBarProps<T> {
  items: ITab<T>[];
  activeTabId: T;

  onTabClick(tab: ITab<T>): void;
}

export function TabBar<T>({ onTabClick, activeTabId, items }: ITabBarProps<T>) {
  return (
    <div className="flex z-10 w-full gap-[1px] text-white">
      {items
        .filter((item) => item.isVisible)
        .map((tab) => (
          <TabBarItem
            isActive={activeTabId === tab.id}
            key={`tab-${tab.id}`}
            tab={tab}
            onClick={onTabClick}
          />
        ))}
    </div>
  );
}
