import { action, computed, makeObservable, observable, reaction } from 'mobx';
import {
  CapiBoundStore,
  ControlStore,
  ICAPI,
  IControlState,
} from 'asu-sim-toolkit';
import { mean, std } from 'mathjs';

import { ICapiModel } from '../capi';
import { getDifference, snipNumber, standardize } from '../utils';
import { IChartControl, IChartStore } from './types';
import { IChartDataSource } from '../sources/chart-data-source.csv';
import {
  ECCENTRICITY,
  IChartDataPoint,
  IMergedDataPoint,
  OBLIQUITY,
  PRECESSION,
  TEMP_CHANGE,
  YEAR_RANGE,
} from './domain';
import { ExternalData } from './external-data';

export class ChartControl<M>
  extends CapiBoundStore<M>
  implements IChartControl
{
  isDisplayed = true;
  controlState: IControlState;
  id = '';

  constructor(
    capi: ICAPI<M>,
    capiIdDisplayed: keyof M,
    capiIdEnabled: keyof M,
    capiIdVisible: keyof M,
    id: string
  ) {
    super(capi);

    this.controlState = new ControlStore(capi, capiIdEnabled, capiIdVisible);
    this.id = id;

    makeObservable(this, {
      isDisplayed: observable,
      setIsDisplayed: action.bound,
    });

    this.bindToCapi('isDisplayed', capiIdDisplayed);
  }

  setIsDisplayed(newValue: boolean) {
    this.isDisplayed = newValue;
  }
}

export class ChartStore
  extends CapiBoundStore<ICapiModel>
  implements IChartStore
{
  private dataSource: IChartDataSource;

  eccentricity: IChartControl;
  precession: IChartControl;
  obliquity: IChartControl;
  tempChange: IChartControl;

  sliderMin = YEAR_RANGE.min;
  sliderMax = YEAR_RANGE.max;
  capiSliderMin = YEAR_RANGE.min;
  capiSliderMax = YEAR_RANGE.max;

  chartData: ExternalData<IChartDataPoint[], void>;
  isMultiselectEnabled = true;
  data: any[] | null = null;

  constructor(capi: ICAPI<ICapiModel>, dataSource: IChartDataSource) {
    super(capi);

    this.dataSource = dataSource;
    this.chartData = new ExternalData<IChartDataPoint[], void>(
      this.dataSource.loadData
    );

    this.eccentricity = new ChartControl(
      capi,
      'Sim.Chart.Eccentricity.Displayed',
      'Sim.Chart.Eccentricity.Enabled',
      'Sim.Chart.Eccentricity.Visible',
      ECCENTRICITY.id
    );

    this.precession = new ChartControl(
      capi,
      'Sim.Chart.Precession.Displayed',
      'Sim.Chart.Precession.Enabled',
      'Sim.Chart.Precession.Visible',
      PRECESSION.id
    );

    this.obliquity = new ChartControl(
      capi,
      'Sim.Chart.Obliquity.Displayed',
      'Sim.Chart.Obliquity.Enabled',
      'Sim.Chart.Obliquity.Visible',
      OBLIQUITY.id
    );

    this.tempChange = new ChartControl(
      capi,
      'Sim.Chart.TempChange.Displayed',
      'Sim.Chart.TempChange.Enabled',
      'Sim.Chart.TempChange.Visible',
      TEMP_CHANGE.id
    );

    makeObservable(this, {
      sliderMin: observable,
      sliderMax: observable,
      capiSliderMin: observable,
      capiSliderMax: observable,
      sliderDifference: computed,
      isMultiselectEnabled: observable,

      setSliderMinMax: action.bound,
      toggleParameter: action.bound,
    });

    this.synchronizeToCapi('capiSliderMin', 'Sim.Chart.TimeSlider.Min');
    this.synchronizeToCapi('capiSliderMax', 'Sim.Chart.TimeSlider.Max');
    this.synchronizeToCapi(
      'sliderDifference',
      'Sim.Chart.TimeSlider.Difference'
    );

    this.synchronizeFromCapi(
      'isMultiselectEnabled',
      'Sim.Chart.Multiselect.Enabled'
    );

    this.onCapi('Sim.Chart.TimeSlider.Min', (newValue: number) => {
      if (newValue !== this.capiSliderMin) {
        this.sliderMin = newValue;
        this.processData();
      }
    });

    this.onCapi('Sim.Chart.TimeSlider.Max', (newValue: number) => {
      if (newValue !== this.capiSliderMax) {
        this.sliderMax = newValue;
        this.processData();
      }
    });

    reaction(
      () => [this.sliderMin, this.sliderMax],
      () => {
        this.capiSliderMin = this.sliderMin;
        this.capiSliderMax = this.sliderMax;
      }
    );

    reaction(
      () => [
        this.chartData.data,
        this.eccentricity.isDisplayed,
        this.obliquity.isDisplayed,
        this.precession.isDisplayed,
      ],
      () => this.processData()
    );

    makeObservable(this, { data: observable.shallow });
  }

  private meanParams = {
    eccentricity: 0,
    obliquity: 0,
    precession: 0,
  };

  private standardDeviationParams = {
    eccentricity: 0,
    obliquity: 0,
    precession: 0,
  };

  private processRawData() {
    const data = this.chartData.data?.sort((a, b) => {
      if (a.year < b.year) return -1;
      if (a.year > b.year) return 1;
      return 0;
    });
    if (!data) {
      return;
    }

    console.log(`Processing raw data. Samples: ${data?.length || 0}`);

    const eccentricity = data.map((d) => d.eccentricity);
    const precession = data.map((d) => d.precession);
    const obliquity = data.map((d) => d.obliquity);

    this.meanParams.eccentricity = mean(...eccentricity);
    this.meanParams.precession = mean(...precession);
    this.meanParams.obliquity = mean(...obliquity);

    this.standardDeviationParams.eccentricity = std(...eccentricity);
    this.standardDeviationParams.precession = std(...precession);
    this.standardDeviationParams.obliquity = std(...obliquity);
  }

  private processData() {
    const data = this.chartData.data;

    if (!data) return;

    if (this.standardDeviationParams.obliquity === 0) this.processRawData();

    const processedData: IMergedDataPoint[] = data.map((d) => ({
      year: Math.abs(d.year) / YEAR_RANGE.divider,
      tempAnomaly: d.tempAnomaly,
      value:
        (this.eccentricity.isDisplayed
          ? standardize(
              d.eccentricity,
              this.meanParams.eccentricity,
              this.standardDeviationParams.eccentricity
            )
          : 0) +
        (this.precession.isDisplayed
          ? standardize(
              d.precession,
              this.meanParams.precession,
              this.standardDeviationParams.precession
            )
          : 0) +
        (this.obliquity.isDisplayed
          ? standardize(
              d.obliquity,
              this.meanParams.obliquity,
              this.standardDeviationParams.obliquity
            )
          : 0),
    }));

    const mergedValues = processedData.map((d) => d.value);
    const avgValue = mean(mergedValues);
    const stdDeviation = std(...mergedValues);

    processedData.forEach(
      (d) =>
        (d.value = snipNumber(standardize(d.value, avgValue, stdDeviation), 2))
    );

    this.data = processedData;
  }

  get sliderDifference() {
    return Math.round(getDifference(this.sliderMin, this.sliderMax));
  }

  setSliderMinMax(sliderMin: number, sliderMax: number) {
    const roundedMin = Math.round(sliderMin);
    const roundedMax = Math.round(sliderMax);

    this.sliderMin = roundedMin;
    this.sliderMax = roundedMax;
    this.capiSliderMin = roundedMin;
    this.capiSliderMax = roundedMax;
  }

  toggleParameter(value: boolean, id: string) {
    const params = [this.eccentricity, this.obliquity, this.precession];
    const toggleId = params.findIndex((p) => p.id === id);
    const toggle = params.splice(toggleId, 1)[0];

    if (!this.isMultiselectEnabled) {
      params.forEach((p) => p.setIsDisplayed(false));
    }

    toggle.setIsDisplayed(value);
  }
}
