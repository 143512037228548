import { IChartDataPoint } from '../stores/domain';
import { loadCSV, Mapper, snipNumber } from '../utils';

export interface IChartDataSource {
  loadData(): Promise<IChartDataPoint[]>;
}

const mapNumber: Mapper<string, number> = (input) => snipNumber(Number(input));

const chartDataMapper: Mapper<string[], IChartDataPoint> = (input) => ({
  year: mapNumber(input[0]),
  tempAnomaly: mapNumber(input[1]),
  eccentricity: mapNumber(input[2]),
  obliquity: mapNumber(input[3]),
  perihelion: mapNumber(input[4]),
  precession: mapNumber(input[5]),
});

export class ChartDataSource implements IChartDataSource {
  constructor(private apiRoot: string) {}

  loadData = () =>
    loadCSV<IChartDataPoint>(`${this.apiRoot}/data.csv`, chartDataMapper);
}
