import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ICapiPanelAction,
  ModalRoot,
  CapiPanel,
  isSafari,
  NotificationArea,
} from 'asu-sim-toolkit';

import { useStores } from '../../providers/store/use-stores';
import { getCapi } from '../../capi';
import { Icon } from '../Icon';
import { ViewType } from '../../stores/domain';
import { ITab } from '../TabBar/types';
import { TabBar } from '../TabBar/TabBar';
import { OrbitView } from '../OrbitView/OrbitView';
import { ChartView } from '../ChartView/ChartView';
import classNames from 'classnames';

const contentComponents: Record<ViewType, ReactNode> = {
  orbit: <OrbitView />,
  chart: <ChartView />,
};

export const MainView = observer(() => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    modalStore,
    notificationStore,
    recapStore: { showRecap, hasNewUpdates },
    fullscreenStore: {
      registerFullscreenElement,
      toggleFullscreen,
      fullscreenControlState,
      isFullscreen,
    },
    appStore: { activeTabId, setActiveTab, orbitTabState, chartTabState },
    chartStore: { chartData },
    orbitStore: { isInsolationVisible },
  } = useStores();

  useEffect(() => {
    if (!wrapperRef.current) return;

    chartData.loadData();

    showRecap();
    registerFullscreenElement(wrapperRef.current);
  }, [chartData, registerFullscreenElement, showRecap]);

  const TABS: ITab<ViewType>[] = [
    {
      id: ViewType.Orbit,
      label: 'Orbit',
      iconId: 'globe',
      isEnabled: orbitTabState.isEnabled,
      isVisible: orbitTabState.isVisible,
    },
    {
      id: ViewType.Chart,
      label: 'Timeline',
      iconId: 'chart',
      isEnabled: chartTabState.isEnabled,
      isVisible: chartTabState.isVisible,
    },
  ];

  const debugActions: ICapiPanelAction[] = useMemo(
    () => [
      {
        iconId: hasNewUpdates ? 'newUpdates' : 'info',
        tooltip: 'Version history',
        onClick: () => showRecap(false),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNewUpdates]
  );

  return (
    <div ref={wrapperRef}>
      <NotificationArea notificationStore={notificationStore} />
      <ModalRoot modalStore={modalStore}>
        <div className="h-screen w-screen pt-1 sm:p-4 shadow-layout">
          <div
            className={classNames(
              'relative h-full flex-1 flex flex-col',
              isFullscreen && 'overflow-y-auto'
            )}
          >
            <TabBar
              items={TABS}
              activeTabId={activeTabId}
              onTabClick={setActiveTab}
            />
            <div className="h-full w-full bg-blue text-white shadow-layout rounded-b-md">
              {contentComponents[activeTabId]}
            </div>
            {fullscreenControlState.isVisible && !isSafari && (
              <button
                className={classNames(
                  'fixed flex items-center justify-center border border-gray-200 bottom-[5px] sm:bottom-lg z-50 bg-white text-blue text-[25px] w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] rounded-md shadow-layout',
                  activeTabId === ViewType.Chart || !isInsolationVisible
                    ? 'right-[5px] sm:right-lg'
                    : 'right-[28px] sm:right-[118px]'
                )}
                disabled={!fullscreenControlState.isEnabled}
                onClick={toggleFullscreen}
              >
                <Icon id={isFullscreen ? 'fullscreen_exit' : 'fullscreen'} />
              </button>
            )}
            <CapiPanel additionalActions={debugActions} capi={getCapi()} />
          </div>
        </div>
      </ModalRoot>
    </div>
  );
});
