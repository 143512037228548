import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { IChartControl } from '../../stores/types';
import { Checkbox } from '../UI/Checkbox';

interface IChartCheckbox {
  label: string;
  className?: string;

  control: IChartControl;
  isDisabled?: boolean;
  onChange(value: boolean, id: string): void;
}

export const ChartCheckbox: FC<IChartCheckbox> = observer(
  ({
    label,
    className,
    control: {
      isDisplayed,
      controlState: { isEnabled, isVisible },
      id,
    },
    isDisabled,
    onChange,
  }) => (
    <div className={className}>
      <Checkbox
        label={label}
        checked={isDisplayed}
        onChange={(value) => onChange(value, id)}
        isVisible={isVisible}
        disabled={!isEnabled || isDisabled}
      />
    </div>
  )
);
