import {
  RecapStore,
  ModalStore,
  NotificationStore,
  INotificationStore,
  IModalStore,
  IRecapStore,
  IFullscreenStore,
  FullscreenStore,
} from 'asu-sim-toolkit';

import { isDebugMode } from '../config';
import { capiSchema, getCapi } from '../capi';
import { versionHistory } from '../version-history';
import { IAppStore, IChartStore, IOrbitStore, IRootStore } from './types';
import { AppStore } from './app-store';
import { OrbitStore } from './orbit-store';
import { ChartStore } from './chart-store';
import {
  ChartDataSource,
  IChartDataSource,
} from '../sources/chart-data-source.csv';

class RootStore implements IRootStore {
  appStore: IAppStore;
  notificationStore: INotificationStore;
  modalStore: IModalStore;
  recapStore: IRecapStore;
  fullscreenStore: IFullscreenStore;

  orbitStore: IOrbitStore;
  chartStore: IChartStore;

  constructor() {
    const capi = getCapi();
    capi.init(capiSchema);

    if (isDebugMode) {
      (window as any).capi = capi;
      (window as any).store = this;
    }

    const chartDataSource: IChartDataSource = new ChartDataSource('');

    this.appStore = new AppStore(this, capi);
    this.notificationStore = new NotificationStore();
    this.modalStore = new ModalStore();
    this.recapStore = new RecapStore(this.modalStore, versionHistory);
    this.fullscreenStore = new FullscreenStore(capi);

    this.orbitStore = new OrbitStore(capi);
    this.chartStore = new ChartStore(capi, chartDataSource);
  }
}

export const rootStore = new RootStore();
