import * as THREE from 'three';

interface IConfig {
  radius: number;
  angle: number;
  shift?: number;
  axis?: 'x' | 'y' | 'z';
  density?: number;
  p0?: number;
}

export const calculateAnglePoints = function ({
  radius,
  angle,
  shift = 0,
  axis = 'x',
  density = 90,
  p0 = 0,
}: IConfig) {
  const points = [];

  for (let i = 0; i <= density; i++) {
    const ang = (i / density) * angle + shift;
    const p1 = radius * Math.cos(ang);
    const p2 = radius * Math.sin(ang);

    if (axis === 'x') {
      points.push(new THREE.Vector3(p1, p0, p2));
    } else if (axis === 'y') {
      points.push(new THREE.Vector3(p0, p1, p2));
    } else {
      points.push(new THREE.Vector3(p1, p2, p0));
    }
  }

  return points;
};
