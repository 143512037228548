/* eslint-disable react/no-unknown-property */

import React, { FC, Ref, useMemo, useRef } from 'react';
import {
  AdditiveBlending,
  DoubleSide,
  Euler,
  Group,
  Mesh,
  TextureLoader,
  Vector3,
} from 'three';
import { useFrame, useLoader } from '@react-three/fiber';
import { MathUtils } from 'three';
import { scaleLinear } from 'd3-scale';

import { INSOLATION } from '../../../stores/domain';
import COLORS from '../../../colors.json';
import { getSphereArgs } from '../utils';
import { EARTH_RADIUS } from '../config';
import { EarthGeometricSet } from './EarthGeometricSet';

interface IEarth {
  earthRef: Ref<any>;
  allOrbitPoints: Vector3[];
  earthPointIndex: number;
  obliquity: number;
  precession: number;
  insolation: number;
  isInsolationVisible: boolean;

  onFrame(): void;
}

export const Earth: FC<IEarth> = ({
  earthRef,
  allOrbitPoints,
  obliquity,
  precession,
  insolation,
  isInsolationVisible,
  earthPointIndex,
  onFrame,
}) => {
  const [diffuseMap, bumpMap, cloudMap, cloudAlphaMap, lightMap] = useLoader(
    TextureLoader,
    [
      'textures/earth/diffuse.jpg',
      'textures/earth/bump.jpg',
      'textures/earth/clouds-color.jpg',
      'textures/earth/clouds-alpha.jpg',
      'textures/earth/lights.jpg',
    ]
  );

  const cloudLayerRef = useRef<Mesh>(null);
  const earthGroupRef = useRef<Group>(null);

  const obliquityMatrix = useMemo(
    () => new Euler(MathUtils.degToRad(obliquity), 0, 0),
    [obliquity]
  );
  const precessionMatrix = useMemo(
    () => new Euler(0, MathUtils.degToRad(precession + 90), 0),
    [precession]
  );
  const insolationRange = scaleLinear<string>()
    .domain([INSOLATION.min, INSOLATION.max])
    .range([COLORS.insolation.low, COLORS.insolation.high]);

  useFrame(() => {
    cloudLayerRef.current?.rotateY(0.0002);
    earthGroupRef.current?.rotateY(0.0006);

    onFrame();
  });

  return (
    <group position={allOrbitPoints[earthPointIndex]} ref={earthRef}>
      <EarthGeometricSet obliquity={obliquity} precession={precession} />

      <group rotation={precessionMatrix}>
        <group rotation={obliquityMatrix}>
          <group ref={earthGroupRef}>
            <mesh>
              <sphereGeometry args={getSphereArgs(EARTH_RADIUS)} />
              <meshStandardMaterial
                map={diffuseMap}
                bumpMap={bumpMap}
                bumpScale={0.01}
                emissiveMap={lightMap}
                emissive="#fffecc"
                emissiveIntensity={0.5}
              />
            </mesh>
            <mesh ref={cloudLayerRef}>
              <sphereGeometry args={getSphereArgs(EARTH_RADIUS + 0.005)} />
              <meshStandardMaterial
                transparent={true}
                map={cloudMap}
                alphaMap={cloudAlphaMap}
                alphaTest={0}
                blending={AdditiveBlending}
                side={DoubleSide}
              />
            </mesh>
            {isInsolationVisible && (
              <mesh>
                <sphereGeometry
                  args={getSphereArgs(
                    EARTH_RADIUS + 0.01,
                    0,
                    Math.PI * 2,
                    0.8,
                    0.14
                  )}
                />
                <meshBasicMaterial
                  color={insolationRange(insolation)}
                  transparent={true}
                  opacity={0.7}
                />
              </mesh>
            )}
          </group>
        </group>
      </group>
    </group>
  );
};
