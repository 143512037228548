/* eslint react/no-unknown-property: "off" */
import { Text } from '@react-three/drei';
import React, { FC } from 'react';
import { BufferGeometry, Vector3 } from 'three';
import { CameraPosition } from '../../stores/domain';
import { shiftPosition } from '../../utils';
import { SpriteText } from '../UI/SpriteText';
import { aphelionRange, perihelionRange } from './utils';

interface IEcliptic {
  points: Vector3[];
  sunPosition: Vector3;
  eccentricity: number;
  largeRadius: number;
  areLabelsVisible: boolean;
  cameraPosition?: CameraPosition;
}

export const Ecliptic: FC<IEcliptic> = ({
  points,
  sunPosition,
  eccentricity,
  largeRadius,
  areLabelsVisible,
  cameraPosition,
}) => {
  const ellipseGeometry = new BufferGeometry().setFromPoints(points);

  const largeDiameterLinePoints: Vector3[] = [
    new Vector3(-largeRadius, 0, 0),
    new Vector3(largeRadius, 0, 0),
  ];

  const perihelionVerticalLineX = Math.abs(sunPosition.x + largeRadius) / 2;

  const aphelionVerticalLineX = -Math.abs(sunPosition.x - largeRadius) / 2;

  const perihelionVerticalLine: Vector3[] = [
    new Vector3(perihelionVerticalLineX, 0, 0),
    new Vector3(perihelionVerticalLineX, 1.5, 0),
  ];

  const aphelionVerticalLine: Vector3[] = [
    new Vector3(aphelionVerticalLineX, 0, 0),
    new Vector3(aphelionVerticalLineX, 1.5, 0),
  ];

  const largeDiameterLineGometry = new BufferGeometry().setFromPoints(
    largeDiameterLinePoints
  );

  const perihelionVerticalLineGeometry = new BufferGeometry().setFromPoints(
    perihelionVerticalLine
  );

  const aphelionVerticalLineGeometry = new BufferGeometry().setFromPoints(
    aphelionVerticalLine
  );

  return (
    <>
      <line_ geometry={ellipseGeometry}>
        <lineBasicMaterial color="gray" linewidth={1} />
      </line_>
      <line_ geometry={largeDiameterLineGometry}>
        <lineBasicMaterial color="gray" linewidth={1} />
      </line_>
      {areLabelsVisible && (
        <>
          <group>
            <SpriteText
              position={shiftPosition(aphelionVerticalLine[1], 'y', 0.25)}
              targetCamera={cameraPosition === CameraPosition.Earth}
            >
              <Text
                position={new Vector3(0, 0.3, 0)}
                fontSize={0.2}
                textAlign="center"
                outlineWidth={0.005}
                outlineColor="white"
              >
                Distance to aphelion
              </Text>
              <Text fontSize={0.2} textAlign="center">
                {aphelionRange(eccentricity).toFixed(0) + ' million km'}
              </Text>
            </SpriteText>
            <line_ geometry={aphelionVerticalLineGeometry}>
              <lineBasicMaterial color="gray" linewidth={1} />
            </line_>
          </group>
          <group>
            <SpriteText
              position={shiftPosition(perihelionVerticalLine[1], 'y', 0.25)}
              targetCamera={cameraPosition === CameraPosition.Earth}
            >
              <Text
                position={new Vector3(0, 0.3, 0)}
                fontSize={0.2}
                textAlign="center"
                outlineWidth={0.005}
                outlineColor="white"
              >
                Distance to perihelion
              </Text>
              <Text fontSize={0.2} textAlign="center">
                {perihelionRange(eccentricity).toFixed(0) + ' million km'}
              </Text>
            </SpriteText>
            <line_ geometry={perihelionVerticalLineGeometry}>
              <lineBasicMaterial color="gray" linewidth={1} />
            </line_>
          </group>
        </>
      )}
    </>
  );
};
