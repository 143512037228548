import { IUpdateEntry } from 'asu-sim-toolkit';

export const versionHistory: IUpdateEntry[] = [
  {
    date: new Date('2022-12-23').getTime(),
    title: '1.0.0',
    description: 'Test build',
    changelog: {
      'Core functionality': [
        {
          description: 'All requested features, hopefully',
          type: 'feature',
        },
        {
          description: 'Merged values in chart are now limited to two decimal places',
          type: 'fix',
        }
      ],
      'Visuals': [
        {
          description: 'Precession arc is no longer detached from Earth axis',
          type: 'fix',
        }
      ]
    },
  },
];
