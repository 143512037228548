import React, { FC, FormEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import {
  CartesianGrid,
  Tooltip,
  XAxis,
  LineChart,
  Line,
  ResponsiveContainer,
  YAxis,
  Brush,
} from 'recharts';

import { useStores } from '../../providers/store/use-stores';
import COLORS from '../../colors.json';
import { useScreenSize } from '../../providers/screen/use-screen-size';
import { scaleLinear } from 'd3-scale';
import { YEAR_RANGE } from '../../stores/domain';
import {
  Formatter,
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

interface BrushIndex {
  startIndex?: number;
  endIndex?: number;
}

type BrushChangeEvent = FormEventHandler<SVGElement> &
  ((newIndex: BrushIndex) => void);

interface IChart {
  mergedLineLabel: string;
}

const tooltipLabelFormatter = (year: number) =>
  `${(year * YEAR_RANGE.divider)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} years ago`;

const tooltipUnitFormatter = (
  value: ValueType,
  _name: NameType,
  { dataKey }: Payload<ValueType, NameType>
) => {
  if (dataKey === 'tempAnomaly') return `${value} (C°)`;
  return value;
};

export const Chart: FC<IChart> = observer(({ mergedLineLabel }) => {
  const screenSize = useScreenSize();
  const {
    chartStore: { data, tempChange, sliderMin, sliderMax, setSliderMinMax },
  } = useStores();

  if (!data) {
    return null;
  }

  const minYear = data[0].year;
  const maxYear = data[data.length - 1].year;

  const timeRange = scaleLinear()
    .domain([minYear, maxYear])
    .rangeRound([0, data.length - 1]);

  const brushStartIndex = timeRange(sliderMin);
  const brushEndIndex = timeRange(sliderMax);

  const onBrushChange = (value: BrushIndex) => {
    const largestPossibleIndex = data.length - 1;

    setSliderMinMax(
      timeRange.invert(value.startIndex || 0),
      timeRange.invert(value.endIndex || largestPossibleIndex)
    );
  };

  const generateTicks = () => {
    const ticks = [];
    const step = Math.floor((brushEndIndex - brushStartIndex + 1) / 12) || 1;

    for (let i = brushStartIndex; i < brushEndIndex; i += step) {
      ticks.push(data[i].year);
    }

    return ticks;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={400}
        height={400}
        data={data}
        margin={{ top: 0, right: screenSize.md ? 0 : 20, left: 0, bottom: 0 }}
        className="[&>svg]:overflow-visible"
      >
        <CartesianGrid strokeDasharray="2 2" />
        <YAxis
          yAxisId={0}
          domain={[-4, 2]}
          scale="linear"
          tickMargin={25}
          stroke="#000"
          tick={{ fontSize: 16, fontWeight: 'normal' }}
          tickLine={false}
          ticks={[-4, -2, 0, 2]}
        />
        <YAxis yAxisId={1} domain={[-3, 3]} scale="linear" hide />
        <XAxis
          dataKey="year"
          stroke="#000"
          name="Thousands of years ago"
          tick={{ fontSize: 15, fontWeight: 'normal' }}
          ticks={generateTicks()}
        />
        <Tooltip
          isAnimationActive
          labelFormatter={tooltipLabelFormatter}
          formatter={
            tooltipUnitFormatter as Formatter<string | number, string | number>
          }
        />
        <Line
          type="basis"
          dataKey="value"
          name={mergedLineLabel}
          stroke={COLORS.chart.rest}
          strokeWidth={3}
          yAxisId={1}
          dot={false}
          connectNulls={true}
          isAnimationActive={false}
          hide={!mergedLineLabel}
        />
        <Line
          type="monotone"
          dataKey="tempAnomaly"
          name="Temperature anomaly"
          stroke={tempChange.isDisplayed ? COLORS.chart.anomaly : 'transparent'}
          strokeWidth={2}
          strokeDasharray="8 2"
          yAxisId={0}
          dot={false}
          connectNulls={true}
          isAnimationActive={false}
        />
        <Brush
          dataKey="year"
          stroke={COLORS.blue.light}
          travellerWidth={14}
          height={28}
          onChange={onBrushChange as BrushChangeEvent}
          startIndex={brushStartIndex}
          endIndex={brushEndIndex}
        />
      </LineChart>
    </ResponsiveContainer>
  );
});
