import {
  CAPI,
  CAPI_TYPES,
  FullscreenCapiSchema,
  GodModeCapiSchema,
  IFullscreenCapiModel,
  IGodModeCapiModel,
  TCapiSchema,
} from 'asu-sim-toolkit';

import { isDebugMode } from './config';
import {
  ECCENTRICITY,
  OBLIQUITY,
  PRECESSION,
  YEAR_RANGE,
} from './stores/domain';
import { getDifference } from './utils';

export enum CapiViewType {
  Orbit = 'orbit',
  Chart = 'chart',
}

export enum CapiCameraPosition {
  Orbit = 'orbit',
  Earth = 'earth',
}

export enum CapiCameraView {
  Side = 'side',
  Top = 'top',
  Custom = 'custom',
}

export enum CapiEarthPosition {
  January = 0,
  April = 1,
  July = 2,
  October = 3,
}

export interface ICapiModel extends IFullscreenCapiModel, IGodModeCapiModel {
  'Sim.View': CapiViewType;
  'Sim.Orbit.Tab.Visible': boolean;
  'Sim.Orbit.Tab.Enabled': boolean;
  'Sim.Chart.Tab.Visible': boolean;
  'Sim.Chart.Tab.Enabled': boolean;

  'Sim.Orbit.CameraPosition.Value': CapiCameraPosition;
  'Sim.Orbit.CameraPosition.Visible': boolean;
  'Sim.Orbit.CameraPosition.Enabled': boolean;
  'Sim.Orbit.CameraPosition.View': CapiCameraView;

  'Sim.Orbit.Eccentricity.Value': number;
  'Sim.Orbit.Eccentricity.Min': number;
  'Sim.Orbit.Eccentricity.Max': number;
  'Sim.Orbit.Eccentricity.Visible': boolean;
  'Sim.Orbit.Eccentricity.Enabled': boolean;

  'Sim.Orbit.Precession.Value': number;
  'Sim.Orbit.Precession.Step': number;
  'Sim.Orbit.Precession.Visible': boolean;
  'Sim.Orbit.Precession.Enabled': boolean;

  'Sim.Orbit.Obliquity.Value': number;
  'Sim.Orbit.Obliquity.Min': number;
  'Sim.Orbit.Obliquity.Max': number;
  'Sim.Orbit.Obliquity.Visible': boolean;
  'Sim.Orbit.Obliquity.Enabled': boolean;

  'Sim.Orbit.EarthPosition.Value': CapiEarthPosition;
  'Sim.Orbit.EarthPosition.IncludeMidPoints': boolean;
  'Sim.Orbit.EarthPosition.Visible': boolean;
  'Sim.Orbit.EarthPosition.Enabled': boolean;

  'Sim.Orbit.Insolation.Visible': boolean;
  'Sim.Orbit.Labels.Visible': boolean;

  'Sim.Chart.Eccentricity.Displayed': boolean;
  'Sim.Chart.Eccentricity.Visible': boolean;
  'Sim.Chart.Eccentricity.Enabled': boolean;

  'Sim.Chart.Precession.Displayed': boolean;
  'Sim.Chart.Precession.Visible': boolean;
  'Sim.Chart.Precession.Enabled': boolean;

  'Sim.Chart.Obliquity.Displayed': boolean;
  'Sim.Chart.Obliquity.Visible': boolean;
  'Sim.Chart.Obliquity.Enabled': boolean;

  'Sim.Chart.TempChange.Displayed': boolean;
  'Sim.Chart.TempChange.Visible': boolean;
  'Sim.Chart.TempChange.Enabled': boolean;

  'Sim.Chart.TimeSlider.Min': number;
  'Sim.Chart.TimeSlider.Max': number;
  'Sim.Chart.TimeSlider.Difference': number;

  'Sim.Chart.Multiselect.Enabled': number;
}

export const capiSchema: TCapiSchema<ICapiModel> = {
  ...GodModeCapiSchema,
  ...FullscreenCapiSchema,
  'Sim.View': {
    initialValue: CapiViewType.Chart,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiViewType),
    },
  },

  'Sim.Orbit.Tab.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.Tab.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Tab.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Tab.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Orbit.CameraPosition.Value': {
    initialValue: CapiCameraPosition.Orbit,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiCameraPosition),
    },
  },
  'Sim.Orbit.CameraPosition.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.CameraPosition.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.CameraPosition.View': {
    initialValue: CapiCameraView.Custom,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiCameraView),
    },
  },

  'Sim.Orbit.Eccentricity.Value': {
    initialValue: ECCENTRICITY.min,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Orbit.Eccentricity.Min': {
    initialValue: ECCENTRICITY.min,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Orbit.Eccentricity.Max': {
    initialValue: ECCENTRICITY.max,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Orbit.Eccentricity.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.Eccentricity.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Orbit.Precession.Value': {
    initialValue: PRECESSION.min,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Orbit.Precession.Step': {
    initialValue: PRECESSION.step,
    options: {
      type: CAPI_TYPES.NUMBER,
      allowedValues: [1, 45, 90, 180],
    },
  },
  'Sim.Orbit.Precession.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.Precession.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Orbit.Obliquity.Value': {
    initialValue: OBLIQUITY.min,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Orbit.Obliquity.Min': {
    initialValue: OBLIQUITY.min,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Orbit.Obliquity.Max': {
    initialValue: OBLIQUITY.max,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Orbit.Obliquity.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.Obliquity.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Orbit.EarthPosition.Value': {
    initialValue: CapiEarthPosition.January,
    options: {
      type: CAPI_TYPES.NUMBER,
      allowedValues: Object.values(CapiEarthPosition),
    },
  },
  'Sim.Orbit.EarthPosition.IncludeMidPoints': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.EarthPosition.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.EarthPosition.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Orbit.Insolation.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Orbit.Labels.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Chart.Eccentricity.Displayed': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Eccentricity.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Eccentricity.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Precession.Displayed': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Precession.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Precession.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Obliquity.Displayed': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Obliquity.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.Obliquity.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.TempChange.Displayed': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.TempChange.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Chart.TempChange.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Chart.TimeSlider.Min': {
    initialValue: YEAR_RANGE.min,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Chart.TimeSlider.Max': {
    initialValue: YEAR_RANGE.max,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Chart.TimeSlider.Difference': {
    initialValue: getDifference(YEAR_RANGE.min, YEAR_RANGE.max),
    options: {
      type: CAPI_TYPES.NUMBER,
      readonly: true,
    },
  },
  'Sim.Chart.Multiselect.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
};

let capi: CAPI<ICapiModel>;

export function getCapi() {
  if (!capi) {
    capi = new CAPI<ICapiModel>(isDebugMode);
  }
  return capi;
}
