import React, { FC, Suspense, useRef } from 'react';
import { Group, Line } from 'three';
import { Canvas, extend, ReactThreeFiber } from '@react-three/fiber';
import classNames from 'classnames';

import { CameraPosition, INSOLATION, ORBIT } from '../../stores/domain';
import { Sun } from './Sun/Sun';
import { Earth } from './Earth/Earth';
import { Ecliptic } from './Ecliptic';
import { OrbitalCamera } from './CameraController';
import { useOrbitData } from './use-orbit-data';
import { Stars } from '@react-three/drei';
import { FrameLimiter } from './FrameLimiter';
import { InsolationIndicator } from '../OrbitView/InsolationIndicator';

extend({ Line_: Line });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      line_: ReactThreeFiber.Object3DNode<Line, typeof Line>;
    }
  }
}

interface IOrbit {
  earthPointIndex: number;
  eccentricity: number;
  obliquity: number;
  precession: number;
  insolation: number;
  isInsolationVisible: boolean;
  areLabelsVisible: boolean;
  inclinationAngle: number;
  cameraPosition?: CameraPosition;
  zoom: number;

  style?: React.CSSProperties;
  className?: string;

  onFrame(): void;
  onCameraInclinationChange(angle: number): void;
  onZoomChange(value: number): void;
}

export const Orbit: FC<IOrbit> = ({
  eccentricity,
  obliquity,
  precession,
  insolation,
  isInsolationVisible,
  areLabelsVisible,
  cameraPosition,
  style,
  className,
  inclinationAngle,
  onCameraInclinationChange,
  earthPointIndex,
  onFrame,
  zoom,
  onZoomChange,
}) => {
  const earthRef = useRef<Group>(null);
  const sunRef = useRef<Group>(null);
  const { largeRadius, points, sunPosition } = useOrbitData({
    eccentricity,
    pointCount: ORBIT.points,
  });

  const cameraSettings =
    cameraPosition === CameraPosition.Earth
      ? {
          targetRef: earthRef,
          distance: 3,
          minDistance: 2.5,
          maxDistance: 6,
          fov: 60,
          xRotation: 45,
        }
      : {
          targetRef: sunRef,
          distance: 25,
          minDistance: 20,
          maxDistance: 50,
          fov: 30,
          xRotation: 0,
        };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        ...style,
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
      }}
      className={classNames('bg-space', className)}
    >
      <div className="absolute left-2 sm:left-5 top-1 sm:top-3 font-light z-50 text-[yellow] text-sm sm:text-md">
        Not to scale
      </div>
      <Canvas frameloop="demand" style={{ touchAction: 'none' }}>
        <Suspense fallback={null}>
          <Stars
            radius={800}
            depth={200}
            count={5000}
            factor={30}
            speed={0.1}
            fade
          />
          <Earth
            allOrbitPoints={points}
            earthPointIndex={earthPointIndex}
            onFrame={onFrame}
            obliquity={obliquity}
            precession={precession}
            insolation={insolation}
            isInsolationVisible={isInsolationVisible}
            earthRef={earthRef}
          />
          <Ecliptic
            eccentricity={eccentricity}
            points={points}
            sunPosition={sunPosition}
            largeRadius={largeRadius}
            cameraPosition={cameraPosition}
            areLabelsVisible={areLabelsVisible}
          />
          <Sun
            groupRef={sunRef}
            position={sunPosition}
            sizeMultiplier={cameraPosition === CameraPosition.Earth ? 0.3 : 1}
          />
          <OrbitalCamera
            cameraSettings={cameraSettings}
            inclinationAngle={inclinationAngle}
            onCameraInclinationChange={onCameraInclinationChange}
            zoom={zoom}
            onZoomChange={onZoomChange}
          />
          <FrameLimiter limit={30} />
        </Suspense>
      </Canvas>
      {isInsolationVisible && (
        <InsolationIndicator
          value={insolation}
          high={INSOLATION.max}
          low={INSOLATION.min}
        />
      )}
    </div>
  );
};
