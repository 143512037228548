import classNames from 'classnames';
import React, { FC } from 'react';

interface ICheckbox {
  label: string;
  checked: boolean;
  disabled?: boolean;
  isVisible?: boolean;

  onChange(value: boolean): void;
}

export const Checkbox: FC<ICheckbox> = ({
  label,
  onChange,
  checked,
  disabled = false,
  isVisible = true,
}) =>
  isVisible ? (
    <div
      className={classNames(
        'py-2 px-3 md:py-3 md:px-5 rounded-full w-full m-3 md:m-2 md:w-fit md-m-0 font-semibold',
        disabled
          ? 'opacity-50 bg-blue-light cursor-not-allowed'
          : checked
          ? 'bg-blue-light cursor-pointer'
          : 'bg-blue-dark cursor-pointer'
      )}
      onClick={() => !disabled && onChange(!checked)}
    >
      <label className="text-sm md:text-md flex items-center justify-center select-none pointer-events-none">
        <input
          type="checkbox"
          className={classNames(
            'checkbox focus:ring-2 focus:ring-outline',
            checked && 'checkbox--checked'
          )}
          checked={checked}
          disabled={disabled}
          readOnly
        />
        <span className="ml-2 mb-[-3px]">{label}</span>
      </label>
    </div>
  ) : null;
