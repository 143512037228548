import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon';
import { IconId } from '../../icon-definitions';

export interface IIconButtonProps {
  iconId: IconId;
  autoFocus?: boolean;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;

  onClick(): void;
}

export const IconButton: FC<IIconButtonProps> = ({
  iconId,
  onClick,
  autoFocus = false,
  className,
  iconClassName,
  disabled = false,
}) => (
  <button
    autoFocus={autoFocus}
    disabled={disabled}
    className={classNames(
      'rounded-full w-[30px] h-[30px] flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-outline',
      disabled && 'opacity-50 hover:opacity-50 cursor-not-allowed',
      className
    )}
    type="button"
    onClick={onClick}
  >
    <Icon id={iconId} className={classNames('text-[20px]', iconClassName)} />
  </button>
);
