import { action, computed, makeObservable, observable, reaction } from 'mobx';
import {
  CapiBoundStore,
  ControlStore,
  ICAPI,
  IControlState,
} from 'asu-sim-toolkit';

import { ICapiModel } from '../capi';
import { IOrbitParams, IOrbitStore } from './types';
import {
  CameraPosition,
  InclinationAngle,
  EarthPositionConfig,
  EARTH_POSITION,
  ECCENTRICITY,
  OBLIQUITY,
  PRECESSION,
  CameraView,
  ORBIT,
} from './domain';
import { mapCameraPositionFromCapi, mapCameraPositionToCapi } from './mappers';
import { calculateInsolation } from '../utils/calculate-insolation';
import { mapEarthPositionToDayOfYear } from '../utils';

export class OrbitStore
  extends CapiBoundStore<ICapiModel>
  implements IOrbitStore
{
  cameraPosition: CameraPosition = CameraPosition.Orbit;
  cameraView: CameraView = CameraView.Custom;
  inclinationAngle: number = InclinationAngle.Default;
  cameraPositionControlState: IControlState;

  eccentricityControlState: IControlState;
  obliquityControlState: IControlState;
  precessionControlState: IControlState;
  earthPositionControlState: IControlState;
  isInsolationVisible = true;
  areLabelsVisible = true;

  includeMidPoints = true;
  earthPosition =
    EARTH_POSITION.configs[
      this.includeMidPoints
        ? EarthPositionConfig.withMidPoints
        : EarthPositionConfig.apsis
    ].min;
  earthPointIndex = mapEarthPositionToDayOfYear(this.earthPosition);
  eccentricity = ECCENTRICITY.min;
  obliquity = OBLIQUITY.min;
  obliquityMin = OBLIQUITY.min;
  obliquityMax = OBLIQUITY.max;
  eccentricityMin = ECCENTRICITY.min;
  eccentricityMax = ECCENTRICITY.max;
  precession = PRECESSION.min;
  precessionStep = PRECESSION.step;

  constructor(capi: ICAPI<ICapiModel>) {
    super(capi);

    this.cameraPositionControlState = new ControlStore<ICapiModel>(
      capi,
      'Sim.Orbit.CameraPosition.Enabled',
      'Sim.Orbit.CameraPosition.Visible'
    );
    this.eccentricityControlState = new ControlStore<ICapiModel>(
      capi,
      'Sim.Orbit.Eccentricity.Enabled',
      'Sim.Orbit.Eccentricity.Visible'
    );
    this.precessionControlState = new ControlStore<ICapiModel>(
      capi,
      'Sim.Orbit.Precession.Enabled',
      'Sim.Orbit.Precession.Visible'
    );
    this.obliquityControlState = new ControlStore<ICapiModel>(
      capi,
      'Sim.Orbit.Obliquity.Enabled',
      'Sim.Orbit.Obliquity.Visible'
    );
    this.earthPositionControlState = new ControlStore<ICapiModel>(
      capi,
      'Sim.Orbit.EarthPosition.Enabled',
      'Sim.Orbit.EarthPosition.Visible'
    );

    makeObservable(this, {
      cameraPosition: observable,
      inclinationAngle: observable,
      cameraView: observable,

      eccentricity: observable,
      eccentricityMin: observable,
      eccentricityMax: observable,
      precession: observable,
      precessionStep: observable,
      obliquity: observable,
      obliquityMin: observable,
      obliquityMax: observable,
      earthPosition: observable,
      earthPointIndex: observable,
      includeMidPoints: observable,

      insolation: computed,
      isInsolationVisible: observable,
      areLabelsVisible: observable,

      setParameters: action.bound,
      setCameraPosition: action.bound,
      setInclinationAngle: action.bound,
      updateEarthPointIndex: action.bound,
    });

    this.bindToCapi(
      'cameraPosition',
      'Sim.Orbit.CameraPosition.Value',
      mapCameraPositionFromCapi,
      mapCameraPositionToCapi
    );
    this.bindToCapi('cameraView', 'Sim.Orbit.CameraPosition.View');

    this.bindToCapi('eccentricity', 'Sim.Orbit.Eccentricity.Value');
    this.bindToCapi('precession', 'Sim.Orbit.Precession.Value');
    this.bindToCapi('precessionStep', 'Sim.Orbit.Precession.Step');
    this.bindToCapi('obliquity', 'Sim.Orbit.Obliquity.Value');
    this.bindToCapi('earthPosition', 'Sim.Orbit.EarthPosition.Value');

    this.synchronizeFromCapi(
      'isInsolationVisible',
      'Sim.Orbit.Insolation.Visible'
    );
    this.synchronizeFromCapi('areLabelsVisible', 'Sim.Orbit.Labels.Visible');

    this.synchronizeFromCapi('eccentricityMin', 'Sim.Orbit.Eccentricity.Min');
    this.synchronizeFromCapi('eccentricityMax', 'Sim.Orbit.Eccentricity.Max');
    this.synchronizeFromCapi('obliquityMin', 'Sim.Orbit.Obliquity.Min');
    this.synchronizeFromCapi('obliquityMax', 'Sim.Orbit.Obliquity.Max');
    this.synchronizeFromCapi(
      'includeMidPoints',
      'Sim.Orbit.EarthPosition.IncludeMidPoints'
    );

    reaction(
      () => this.includeMidPoints,
      () => {
        this.earthPosition =
          EARTH_POSITION.configs[
            this.includeMidPoints
              ? EarthPositionConfig.withMidPoints
              : EarthPositionConfig.apsis
          ].min;
      }
    );
    reaction(
      () => this.precessionStep,
      () => {
        this.precession = PRECESSION.min;
      }
    );
    reaction(
      () => this.inclinationAngle,
      () => {
        if (
          this.inclinationAngle >= 0 &&
          this.inclinationAngle <
            InclinationAngle.Side + InclinationAngle.Offset
        ) {
          this.cameraView = CameraView.Side;
          return;
        }
        if (
          this.inclinationAngle <= InclinationAngle.Top &&
          this.inclinationAngle > InclinationAngle.Top - InclinationAngle.Offset
        ) {
          this.cameraView = CameraView.Top;
          return;
        }
        this.cameraView = CameraView.Custom;
      }
    );
    reaction(
      () => this.cameraView,
      () => {
        if (
          this.cameraView === CameraView.Side &&
          this.inclinationAngle >=
            InclinationAngle.Side + InclinationAngle.Offset
        ) {
          this.inclinationAngle = 0;
          return;
        }
        if (
          this.cameraView === CameraView.Top &&
          this.inclinationAngle <=
            InclinationAngle.Top - InclinationAngle.Offset
        ) {
          this.inclinationAngle = 90;
          return;
        }
        if (
          this.cameraView === CameraView.Custom &&
          (this.inclinationAngle >
            InclinationAngle.Top - InclinationAngle.Offset ||
            this.inclinationAngle <
              InclinationAngle.Side + InclinationAngle.Offset)
        ) {
          this.inclinationAngle = 30;
        }
      }
    );
  }

  setParameters(params: Partial<IOrbitParams>) {
    Object.assign(this, params);
  }

  setCameraPosition(newValue: CameraPosition) {
    this.cameraPosition = newValue;
  }

  setInclinationAngle(newValue: InclinationAngle) {
    this.inclinationAngle = newValue;
  }

  updateEarthPointIndex() {
    const targetPointIndex = mapEarthPositionToDayOfYear(this.earthPosition);

    if (this.earthPointIndex !== targetPointIndex) {
      this.earthPointIndex = (this.earthPointIndex + 1) % ORBIT.points;
    }
  }

  get insolation() {
    return calculateInsolation({
      precession: this.precession,
      latitude: 45,
      obliquity: this.obliquity,
      eccentricity: this.eccentricity,
      dayOfYear: this.earthPointIndex,
    });
  }
}
