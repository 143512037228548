export type CSVLineMapper<T> = (input: string[]) => T;

async function* makeTextFileLineIterator(url: string) {
  const utf8Decoder = new TextDecoder('utf-8');
  const response = await fetch(url);

  if (!response.ok || !response.body) {
    throw new Error('Network error');
  }

  const reader = response.body.getReader();

  let { value: rawChunk, done: readerDone } = await reader.read();

  let chunk = rawChunk ? utf8Decoder.decode(rawChunk) : '';

  const re = /\r\n|\n|\r/gm;
  let startIndex = 0;

  while (true) {
    const result = re.exec(chunk);

    if (!result) {
      if (readerDone) {
        break;
      }

      const remainder = chunk.substring(startIndex);

      ({ value: rawChunk, done: readerDone } = await reader.read());
      chunk = remainder + (rawChunk ? utf8Decoder.decode(rawChunk) : '');
      startIndex = re.lastIndex = 0;
      continue;
    }

    yield chunk.substring(startIndex, result.index);
    startIndex = re.lastIndex;
  }

  if (startIndex < chunk.length) {
    // last line didn't end in a newline char
    yield chunk.substring(startIndex);
  }
}

export async function loadCSV<T>(
  url: string,
  mapper: CSVLineMapper<T>
): Promise<T[]> {
  const result: T[] = [];

  for await (const line of makeTextFileLineIterator(url)) {
    if (line.startsWith('#')) {
      continue;
    }
    result.push(mapper(line.split(',')));
  }

  return result;
}
