/* eslint react/no-unknown-property: "off" */
import React, { FC, ReactNode, useRef } from 'react';
import { Text } from '@react-three/drei';
import { ReactThreeFiber, useFrame, useThree } from '@react-three/fiber';
import { BufferGeometry, Group, Material, Mesh, Vector3 } from 'three';

interface ISpriteText
  extends ReactThreeFiber.NodeProps<
    Mesh<BufferGeometry, Material | Material[]>,
    typeof Mesh
  > {
  children: ReactNode;
  position: Vector3;
  fontSize?: number;
  anchorY?:
    | number
    | 'top'
    | 'bottom'
    | 'middle'
    | 'top-baseline'
    | 'bottom-baseline';
  anchorX?: number | 'center' | 'left' | 'right';
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  targetCamera?: boolean;
  color?: string;
  fontWeight?: number;
}

export const SpriteText: FC<ISpriteText> = ({
  children,
  position,
  fontSize = 0.3,
  color = 'white',
  fontWeight,
  targetCamera,
  ...rest
}) => {
  const { camera } = useThree();
  const textRef = useRef<Group>(null);
  const target = new Vector3(0, 0, 0);

  useFrame(() => {
    if (!textRef.current) return;
    camera.getWorldPosition(target);

    textRef.current.lookAt(
      new Vector3(
        targetCamera ? target.x : target.x + position.x,
        target.y,
        target.z
      )
    );
  });

  return (
    <group ref={textRef} position={position}>
      {typeof children === 'string' ? (
        <Text
          fontSize={fontSize}
          color={color}
          outlineColor={color}
          outlineWidth={fontWeight}
          {...rest}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </group>
  );
};
