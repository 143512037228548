import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { getPercentage } from '../../utils';

interface ITextPerValue {
  value: number;
  text: string;
}
interface IRangeInput
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'onChange'
  > {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange(value: number): void;
  text?: string;
  isVisible?: boolean;
  unit?: string;
  className?: string;
  showSteps?: boolean;
  textPerValues?: ITextPerValue[];
  customMin?: string;
  customMax?: string;
}

export const RangeInput: FC<IRangeInput> = ({
  text,
  value,
  onChange,
  unit = '',
  isVisible = true,
  className,
  min,
  max,
  step,
  showSteps,
  textPerValues,
  customMin,
  customMax,
  ...rest
}) => {
  const [inputEventState, setInputEventState] = useState<
    'none' | 'blurred' | 'focused'
  >('none');
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const leftOffset = `${getPercentage(value - min, max - min)}%`;
  const numberOfSteps = Math.round((max - min) / step);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (inputEventState === 'blurred') {
      timeout = setTimeout(() => {
        setIsTooltipVisible(false);
      }, 2000);
    }

    if (inputEventState === 'focused') {
      setIsTooltipVisible(true);
    }

    return () => {
      timeout ? clearTimeout(timeout) : null;
    };
  }, [inputEventState]);

  const getTooltipText = () => {
    const customText = textPerValues
      ? textPerValues.find((el) => el.value === value)
      : null;

    if (customText) return customText.text;
    return value + unit;
  };

  return isVisible ? (
    <div className={className}>
      {text && (
        <div className="mb-1">
          <label
            htmlFor={`range-${text}`}
            className="font-semibold text-sm sm:text-md md:text-lg text-white"
          >
            {text}
          </label>
        </div>
      )}
      <div className="h-[34px] sm:h-[38px] lg:h-[43px] flex items-center justify-center bg-blue-dark rounded-full px-3">
        <div className="text-sm md:text-md">
          {customMin ? customMin : min + unit}
        </div>
        <div className="w-full h-full flex items-center justify-center relative mx-3 md:mx-4 pointer-events-none">
          <input
            {...rest}
            min={min}
            max={max}
            step={step}
            type="range"
            id={`range-${text}`}
            value={value}
            onChange={(e) => {
              const value = Number(e.target.value);
              onChange(value);
            }}
            onTouchStart={() => setInputEventState('focused')}
            onTouchEnd={() => setInputEventState('blurred')}
            onFocus={() => setInputEventState('focused')}
            onBlur={() => setInputEventState('blurred')}
            className="slider rounded-lg appearance-none pointer-events-auto z-10"
          />
          <div className="absolute left-[10px] right-[10px] h-full">
            {showSteps &&
              Array.from(Array(numberOfSteps + 1).keys()).map((step) => (
                <div
                  className="absolute w-[1px] bg-blue-light h-[20%] z-0"
                  style={{ left: `${(step / numberOfSteps) * 100}%` }}
                  key={`step-${step}`}
                />
              ))}
            <div
              className={classNames(
                'absolute z-20 min-w-[60px] top-0 whitespace-nowrap bg-white text-black rounded-md py-[2px] px-sm flex items-center justify-center select-none transition-opacity duration-300 translate-x-[-50%] translate-y-[-125%]',
                isTooltipVisible ? 'opacity-100' : 'opacity-0'
              )}
              style={{
                left: leftOffset,
              }}
            >
              {getTooltipText()}
              <div className="absolute left-1/2 bottom-0 border-transparent border-[5px] border-t-white translate-x-[-50%] translate-y-[10px]" />
            </div>
          </div>
        </div>
        <div className="text-sm md:text-md">
          {customMax ? customMax : max + unit}
        </div>
      </div>
    </div>
  ) : null;
};
