/**
 * Ported from Mia Brunkhorst's (ASU) code.
 * Mostly added types and reorganized into functions.
 */

import {
  dot,
  matrix,
  Matrix,
  multiply,
  norm,
  range,
  size,
  square,
  squeeze,
  transpose,
} from 'mathjs';

const ORBITAL_PERIOD = 365.256363;
const SOLAR_CONSTANT = 1366; // W/m^2
const AU = 149.5978707; // millions km
const EARTH_ORBIT_SEMI_MAJOR_AXIS = 1.00000261 * AU; // millions km
const PI = Math.PI;
export const PERIHELION_DAY = 2;
export const APHELION_DAY = 184;

function keplerianInverse(
  period: number,
  eccentricity: number,
  meanAnomaly: number
) {
  meanAnomaly = (meanAnomaly * PI) / 180;
  const N = 55; //number of steps we're going to take

  let F = Math.sign(meanAnomaly);
  meanAnomaly = Math.abs(meanAnomaly) / (2 * PI);
  meanAnomaly = (meanAnomaly - Math.floor(meanAnomaly)) * (2 * PI * F);

  if (meanAnomaly < 0) {
    meanAnomaly = meanAnomaly + 2 * PI;
  }

  F = 1;
  if (meanAnomaly > PI) {
    F = -1;
    meanAnomaly = 2 * PI - meanAnomaly;
  }

  let Eo = PI / 2;
  let D = PI / 4;

  for (let i = 0; i < N; i++) {
    const m1 = Eo - eccentricity * Math.sin(Eo);
    Eo = Eo + D * Math.sign(meanAnomaly - m1);
    D = D / 2;
  }

  Eo = Eo * F;

  let nu =
    (2 *
      Math.atan(
        Math.tan(Eo / 2) * Math.sqrt((1 + eccentricity) / (1 - eccentricity))
      ) *
      180) /
    PI;

  if (nu < 0) {
    nu += 360;
  }

  return nu;
}

function generateRotationMatrix(obliquity: number, precession: number) {
  const cosEps = Math.cos(-obliquity);
  const sinEps = Math.sin(-obliquity);
  const onemCosEps = 1 - cosEps;
  const u = Math.cos(precession);
  const v = Math.sin(precession);
  const w = 0;

  let tiltM = matrix([
    [
      cosEps + square(u) * onemCosEps,
      u * v * onemCosEps - w * sinEps,
      u * w * onemCosEps + v * sinEps,
    ],
    [
      u * v * onemCosEps + w * sinEps,
      cosEps + square(v) * onemCosEps,
      v * w * onemCosEps - u * sinEps,
    ],
    [
      u * w * onemCosEps - v * sinEps,
      v * w * onemCosEps + u * sinEps,
      cosEps + square(w) * onemCosEps,
    ],
  ]);

  tiltM = transpose(tiltM);

  return tiltM;
}

function orbit(
  eccentricity: number,
  obliquity: number,
  precession: number,
  meanAnomaly: number
) {
  obliquity = (obliquity * PI) / 180;
  precession = (precession * PI) / 180;

  const trueAnomaly = keplerianInverse(
    ORBITAL_PERIOD,
    eccentricity,
    meanAnomaly
  );

  const positionE = (trueAnomaly * PI) / 180; // for parameterizing radius-vector of Earth

  const r =
    (EARTH_ORBIT_SEMI_MAJOR_AXIS * (1 - square(eccentricity))) /
    (1 + eccentricity * Math.cos(positionE));
  const rVector = [r * Math.cos(positionE), r * Math.sin(positionE), 0];

  const R = 0.15 * EARTH_ORBIT_SEMI_MAJOR_AXIS;

  const tiltM = generateRotationMatrix(obliquity, precession);

  const k = transpose([0, 0, R * 1.5]);
  const kk = multiply(tiltM, k);

  const solarDec =
    (180 / PI) *
      Math.acos(
        dot(rVector, kk) / ((norm(kk) as number) * (norm(rVector) as number))
      ) -
    90;

  return {
    r,
    solarDec,
  };
}

function getTimeElapsed(dayOfYear: number) {
  let timeElapsed = dayOfYear - PERIHELION_DAY;

  if (timeElapsed < 0) {
    timeElapsed = ORBITAL_PERIOD + timeElapsed;
  }

  return timeElapsed;
}

export interface ICalculateInsolationParams {
  latitude: number;
  eccentricity: number;
  obliquity: number;
  precession: number;
  dayOfYear: number;
}

export function calculateInsolation({
  dayOfYear,
  precession,
  latitude,
  obliquity,
  eccentricity,
}: ICalculateInsolationParams) {
  const timeElapsed = getTimeElapsed(dayOfYear);

  const meanAnomaly = (360 / ORBITAL_PERIOD) * timeElapsed;

  precession += 90;

  let adjustedPrecession = 180 - precession;

  if (adjustedPrecession < 0) {
    adjustedPrecession = 360 + adjustedPrecession;
  }

  const orbitData = orbit(
    eccentricity,
    obliquity,
    adjustedPrecession,
    meanAnomaly
  );

  const solarDec = (orbitData.solarDec * PI) / 180;
  latitude = (latitude * PI) / 180;

  let dayLength = 0;
  let FBar = 0;
  let tSunset;
  let t: Matrix | null = null;
  let flag = 0;
  let tHigh = 0;

  if (solarDec >= 0) {
    if (latitude <= solarDec - PI / 2) {
      dayLength = 0;
      FBar = 0;
      flag = 0;
    } else if (latitude >= PI / 2 - solarDec) {
      dayLength = 24;
      t = range(0, 2 * PI, 0.001);
      tHigh = 2 * PI;
      flag = 1;
    } else {
      flag = 1;
      dayLength =
        (24 / PI) * Math.acos(-Math.tan(latitude) * Math.tan(solarDec));
      tSunset = Math.acos(-Math.tan(solarDec) * Math.tan(latitude));
      t = range(0, tSunset, 0.001);
      tHigh = tSunset;
    }
  } else if (solarDec < 0) {
    if (latitude >= solarDec + PI / 2) {
      dayLength = 0;
      FBar = 0;
      flag = 0;
    } else if (latitude <= -PI / 2 - solarDec) {
      dayLength = 24;
      t = range(0, 2 * PI, 0.001);
      tHigh = 2 * PI;
      flag = 1;
    } else {
      flag = 1;
      dayLength =
        (24 / PI) * Math.acos(-Math.tan(latitude) * Math.tan(solarDec));
      tSunset = Math.acos(-1 * Math.tan(solarDec) * Math.tan(latitude));
      t = range(0, tSunset, 0.001);
      tHigh = tSunset;
    }
  }

  if (flag === 1) {
    // Now we have to do some numerical integration using the trapezoidal rule, because no one wants to do messy integrals...

    const y = (x: number) =>
      Math.sin(solarDec) * Math.sin(latitude) +
      Math.cos(solarDec) * Math.cos(latitude) * Math.cos(x);

    const trapezoidal = (a: number, b: number, nn: number) => {
      // Grid spacing
      const h = (b - a) / nn;

      // Computing sum of first and last terms in above formula
      let s = y(a) + y(b);

      // Adding middle terms in above formula
      for (let i = 1; i < nn; i++) {
        s += 2 * y(a + i * h);
      }

      return (h / 2) * s;
    };

    const x0 = 0;
    const xn = tHigh;

    const n1 = squeeze(size(t as Matrix)) as unknown as number;

    const trapz = trapezoidal(x0, xn || 0, n1);

    const Ford = SOLAR_CONSTANT * square(AU / orbitData.r);
    const sinhAve = (1 / tHigh) * trapz;

    FBar = Ford * sinhAve;
    FBar = (FBar * dayLength) / 24;

    return FBar;
  } else {
    return FBar;
  }
}
