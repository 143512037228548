import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { Orbit } from '../Orbit/Orbit';
import { RangeInput } from '../UI/RangeInput';
import {
  ECCENTRICITY,
  EARTH_POSITION,
  OBLIQUITY,
  PRECESSION,
  EarthPositionConfig,
  ZOOM,
} from '../../stores/domain';
import { ViewControl } from './ViewControl';

import { MAX_INCLINATION_ANGLE, MIN_INCLINATION_ANGLE } from '../Orbit/config';

export const OrbitView = observer(() => {
  const {
    orbitStore: {
      eccentricity,
      eccentricityMin,
      eccentricityMax,
      precession,
      precessionStep,
      obliquity,
      obliquityMin,
      obliquityMax,
      earthPosition,
      includeMidPoints,
      eccentricityControlState,
      obliquityControlState,
      cameraPositionControlState,
      cameraPosition,
      inclinationAngle,
      setInclinationAngle,
      precessionControlState,
      earthPositionControlState,
      setParameters,
      setCameraPosition,
      insolation,
      isInsolationVisible,
      areLabelsVisible,
      earthPointIndex,
      updateEarthPointIndex,
    },
  } = useStores();
  const [zoom, setZoom] = useState(ZOOM.default);

  useEffect(() => {
    setZoom(ZOOM.default);
  }, [cameraPosition]);

  const handleZoomChange = (value: number) => {
    if (value <= ZOOM.min) {
      setZoom(ZOOM.min);
      return;
    }

    if (value >= ZOOM.max) {
      setZoom(ZOOM.max);
      return;
    }

    setZoom(Math.round(value / ZOOM.tick) * ZOOM.tick);
  };

  const rangeInputClasses =
    ' sm:pb-2 md:pb-1 lg:pb-0 px-1 sm:px-2 flex-1 min-w-[200px] basis-1/2 lg:basis-0';

  const earthPositionConfig =
    EARTH_POSITION.configs[
      includeMidPoints
        ? EarthPositionConfig.withMidPoints
        : EarthPositionConfig.apsis
    ];

  return (
    <div className="w-full h-full flex flex-col items-center">
      <div className="pb-2 sm:py-1 md:py-3 lg:py-4 px-1 sm:px-4 xl:px-6 w-full flex flex-wrap items-center justify-center">
        <RangeInput
          min={eccentricityMin}
          max={eccentricityMax}
          step={ECCENTRICITY.step}
          text="Eccentricity"
          value={eccentricity}
          disabled={!eccentricityControlState.isEnabled}
          isVisible={eccentricityControlState.isVisible}
          onChange={(v) => setParameters({ eccentricity: v })}
          className={rangeInputClasses}
        />
        <RangeInput
          unit="°"
          min={obliquityMin}
          max={obliquityMax}
          step={OBLIQUITY.step}
          text="Obliquity"
          value={obliquity}
          disabled={!obliquityControlState.isEnabled}
          isVisible={obliquityControlState.isVisible}
          onChange={(v) => setParameters({ obliquity: v })}
          className={rangeInputClasses}
        />
        <RangeInput
          unit="°"
          min={PRECESSION.min}
          max={PRECESSION.max}
          step={precessionStep}
          text="Precession"
          value={precession}
          disabled={!precessionControlState.isEnabled}
          isVisible={precessionControlState.isVisible}
          onChange={(v) => setParameters({ precession: v })}
          className={rangeInputClasses}
          showSteps={PRECESSION.max / precessionStep <= 8}
        />
        <RangeInput
          min={earthPositionConfig.min}
          max={earthPositionConfig.max}
          step={earthPositionConfig.step}
          text="Position"
          value={earthPosition}
          disabled={!earthPositionControlState.isEnabled}
          isVisible={earthPositionControlState.isVisible}
          onChange={(v) => setParameters({ earthPosition: v })}
          className={rangeInputClasses}
          showSteps
          textPerValues={earthPositionConfig.textPerValues}
          customMin={earthPositionConfig.customMin}
          customMax={earthPositionConfig.customMax}
        />
      </div>
      <div className="relative w-full flex-1 min-h-[550px]">
        <Orbit
          eccentricity={eccentricity}
          precession={precession}
          obliquity={obliquity}
          insolation={insolation}
          earthPointIndex={earthPointIndex}
          onFrame={updateEarthPointIndex}
          cameraPosition={cameraPosition}
          inclinationAngle={inclinationAngle}
          isInsolationVisible={isInsolationVisible}
          areLabelsVisible={areLabelsVisible}
          className="flex-1 rounded-b-md"
          onCameraInclinationChange={setInclinationAngle}
          zoom={zoom}
          onZoomChange={(v) => setZoom(v)}
        />
        <ViewControl
          isVisible={cameraPositionControlState.isVisible}
          isEnabled={cameraPositionControlState.isEnabled}
          activeView={cameraPosition}
          inclinationAngle={inclinationAngle}
          onCameraInclinationChange={(v) => setInclinationAngle(v)}
          minInclinationAngle={MIN_INCLINATION_ANGLE}
          maxInclinationAngle={MAX_INCLINATION_ANGLE}
          onChange={(v) => setCameraPosition(v)}
          zoom={zoom}
          onZoomChange={handleZoomChange}
        />
      </div>
    </div>
  );
});
