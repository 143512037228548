import { useContext } from 'react';
import { ScreenSizeContext, IScreenSize } from './screen-size-provider';

export function useScreenSize(): IScreenSize {
  const context = useContext(ScreenSizeContext);

  if (context === null) {
    throw new Error('useScreenSize must be used within ScreenSizeProvider');
  }

  return context;
}
