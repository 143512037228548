import classNames from 'classnames';
import React, { FC } from 'react';
import { CameraPosition, ZOOM } from '../../stores/domain';
import { Icon } from '../Icon';
import { Button } from '../UI/Button';
import { IconButton } from '../UI/IconButton';

interface IViewControl {
  isVisible: boolean;
  isEnabled: boolean;
  activeView: CameraPosition;
  inclinationAngle: number;
  minInclinationAngle: number;
  maxInclinationAngle: number;
  zoom: number;

  onChange(value: CameraPosition): void;
  onCameraInclinationChange(value: number): void;
  onZoomChange(value: number): void;
}

export const ViewControl: FC<IViewControl> = ({
  isVisible,
  isEnabled,
  activeView,
  onChange,
  inclinationAngle,
  minInclinationAngle,
  maxInclinationAngle,
  zoom,
  onCameraInclinationChange,
  onZoomChange,
}) =>
  isVisible ? (
    <div className="absolute flex items-center justify-center gap-sm sm:gap-md bottom-1 sm:bottom-2 sm:bottom-4 left-1 sm:left-2 sm:left-4 z-10 bg-blue-dark rounded-full p-1 h-[38px] sm:h-[42px]">
      <div className="absolute left-0 top-[-26px] font-semibold">View</div>
      <div className="relative z-[-1] bg-blue-light rounded-full p-0 sm:p-[2px] flex items-center justify-center gap-[2px] sm:gap-[4px] h-[30px] sm:h-[34px]">
        <IconButton
          disabled={!isEnabled}
          className={classNames(
            'z-1',
            activeView === CameraPosition.Orbit ? 'text-white' : 'text-skyblue'
          )}
          onClick={() => onChange(CameraPosition.Orbit)}
          iconId="sun"
        />
        <IconButton
          disabled={!isEnabled}
          className={classNames(
            'z-1',
            activeView === CameraPosition.Earth ? 'text-white' : 'text-skyblue'
          )}
          onClick={() => onChange(CameraPosition.Earth)}
          iconId="globe"
        />
        <div
          className={classNames(
            'transition-transfrom duration-300 ease-in-out absolute z-[-1] bg-blue rounded-full w-[26px] sm:w-[30px] h-[26px] sm:h-[30px] top-[2px]',
            activeView === CameraPosition.Orbit
              ? 'left-[2px]'
              : 'left-[calc(50%+3px)] sm:left-[calc(50%+2px)]'
          )}
        />
      </div>
      <div className="flex items-center justify-center gap-md text-sm">
        <Button
          onClick={() => {
            onCameraInclinationChange(minInclinationAngle);
          }}
        >
          Side
        </Button>
        <div className="w-[50px] sm:w-full">
          <input
            min={minInclinationAngle}
            max={maxInclinationAngle}
            step={1}
            type="range"
            id="viewPosition"
            value={inclinationAngle}
            onChange={(e) => {
              const value = Number(e.target.value);
              onCameraInclinationChange(value);
            }}
            className="slider rounded-lg appearance-none"
          />
        </div>
        <Button
          onClick={() => {
            onCameraInclinationChange(maxInclinationAngle);
          }}
        >
          Top
        </Button>
      </div>
      <div className="w-[1px] h-4/5 bg-blue-light mx-1" />
      <div className="flex items-center justify-center mr-1 sm:mr-2">
        <Button
          onClick={() => {
            onZoomChange(zoom - ZOOM.tick);
          }}
          className="mr-1 sm:mr-2"
        >
          <Icon
            id="circleMinus"
            className="text-skyblue text-[19px] sm:text-[21px]"
          />
        </Button>
        <div className="w-[40px] text-center leading-[1px]">{zoom}%</div>
        <Button
          onClick={() => {
            onZoomChange(zoom + ZOOM.tick);
          }}
          className="ml-1 sm:ml-2"
        >
          <Icon
            id="circlePlus"
            className="text-skyblue text-[19px] sm:text-[21px]"
          />
        </Button>
      </div>
    </div>
  ) : null;
