import React, { FC } from 'react';
import { useScreenSize } from '../../providers/screen/use-screen-size';
import { clamp, getPercentage } from '../../utils';
import { Icon } from '../Icon';
import classNames from 'classnames';

interface IInsolationIndicator {
  value: number;
  high: number;
  low: number;
}

export const InsolationIndicator: FC<IInsolationIndicator> = ({
  value,
  high,
  low,
}) => {
  const screenSize = useScreenSize();

  const percentage = clamp(getPercentage(value - low, high - low), 0, 100);

  const isOffTheTop = value > high;
  const isOffTheBottom = value < low;

  return (
    <div className="relative h-full w-[25px] sm:w-[100px] flex flex-col bg-gray-900 text-white text-center overflow-hidden">
      <div className="flex-1 flex flex-col items-center justify-center">
        <span className="m-2">{screenSize.sm ? 'H' : 'high'}</span>
        <div className="relative flex-1 h-full w-3/5 sm:w-2/5 bg-gradient-to-b from-insolation-high to-insolation-low">
          <Icon
            id="borderArrow"
            className={classNames(
              'absolute text-[18px] sm:text-[25px] drop-shadow-hard translate-y-1/2',
              isOffTheBottom && 'transform-indicator-down',
              isOffTheTop && 'transform-indicator-up',
              !(isOffTheTop || isOffTheBottom) && 'left-[-6px] sm:left-[-4px]'
            )}
            style={{
              bottom: `${percentage}%`,
            }}
          />
        </div>
        <span className="m-2">{screenSize.sm ? 'L' : 'low'}</span>
      </div>
      <div className="bg-gray-800 font-semibold py-2">
        {screenSize.sm ? 'In' : 'Insolation'}
      </div>
    </div>
  );
};
