import React from 'react';
import { extend } from '@react-three/fiber';
import { Effects } from '@react-three/drei';
import { UnrealBloomPass } from 'three-stdlib';

extend({ UnrealBloomPass });

export const Glow = () => (
  <Effects disableGamma>
    <unrealBloomPass threshold={1} strength={2} radius={1} />
  </Effects>
);
