import { action, makeObservable, observable, runInAction } from 'mobx';

export enum ExternalDataStatus {
  idle,
  loading,
  ready,
  error,
}

export class ExternalData<T, P> {
  status: ExternalDataStatus = ExternalDataStatus.idle;
  data: T | null = null;
  error: string | null = null;

  constructor(private dataFetchingStrategy: (params: P) => Promise<T>) {
    makeObservable(this, {
      status: observable,
      data: observable.shallow,
      error: observable,
      loadData: action.bound,
    });
  }

  async loadData(params: P): Promise<T | null> {
    if (this.status === ExternalDataStatus.loading) {
      return null;
    }

    this.status = ExternalDataStatus.loading;

    try {
      this.error = null;
      this.data = null;
      this.data = await this.dataFetchingStrategy(params);
      runInAction(() => (this.status = ExternalDataStatus.ready));
    } catch (err) {
      runInAction(() => {
        this.status = ExternalDataStatus.error;
        this.error = String(err);
      });
    }

    return this.data;
  }
}
