/* eslint react/no-unknown-property: "off" */
import React, { Ref } from 'react';
import { Vector3, Group, TextureLoader } from 'three';

import { getSphereArgs } from '../utils';
import { FEATURE_GLOW_IS_ENABLED, SUN_RADIUS } from '../config';
import { useLoader } from '@react-three/fiber';
import { Glow } from '../Glow';

interface ISunProps {
  groupRef: Ref<Group>;
  position: Vector3;
  sizeMultiplier: number;
}

export const Sun = ({ groupRef, position, sizeMultiplier }: ISunProps) => {
  const [sunMap] = useLoader(TextureLoader, ['textures/sun.jpg']);

  const renderMaterial = () =>
    FEATURE_GLOW_IS_ENABLED ? (
      <meshStandardMaterial
        color={[1, 4, 0.5]}
        emissive={[1, 4, 0.8]}
        toneMapped={false}
      />
    ) : (
      <meshBasicMaterial map={sunMap} lightMap={sunMap} lightMapIntensity={2} />
    );

  return (
    <group ref={groupRef}>
      <mesh position={position}>
        <sphereGeometry args={getSphereArgs(SUN_RADIUS * sizeMultiplier)} />
        {renderMaterial()}
      </mesh>
      <pointLight color="#f5f4eb" intensity={1} position={position} />
      {FEATURE_GLOW_IS_ENABLED && <Glow />}
    </group>
  );
};
