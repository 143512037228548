import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';

export const FrameLimiter = ({ limit = 60 }) => {
  const { invalidate, clock } = useThree();
  useEffect(() => {
    let delta = 0;
    const interval = 1 / limit;
    const update = () => {
      requestAnimationFrame(update);
      delta += clock.getDelta();

      if (delta > interval) {
        invalidate();
        delta = delta % interval;
      }
    };

    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
