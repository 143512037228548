import { useMemo } from 'react';
import { EllipseCurve, Vector3 } from 'three';
import { scaleLinear } from 'd3-scale';
import { ECCENTRICITY } from '../../stores/domain';

interface IOrbitDataOptions {
  eccentricity: number;
  /** Remember that point count is decreased by 1 to prevent anomalies related to the position of objects in orbit. */
  pointCount: number;
}

const radius = 8;

const eccentricityRange = scaleLinear()
  .domain([ECCENTRICITY.min, ECCENTRICITY.max])
  .range([0, 1]);

const sunOffsetRange = scaleLinear()
  .domain([ECCENTRICITY.min, ECCENTRICITY.max])
  .range([0, 2]);

export function useOrbitData({ eccentricity, pointCount }: IOrbitDataOptions) {
  return useMemo(() => {
    const smallRadius = radius - eccentricityRange(eccentricity) / 2;
    const largeRadius = radius + eccentricityRange(eccentricity);

    const curve = new EllipseCurve(
      0,
      0,
      largeRadius,
      smallRadius,
      0,
      2 * Math.PI,
      false,
      0
    );
    const curvePoints = curve.getPoints(pointCount - 1).reverse();

    return {
      points: curvePoints.map((p) => new Vector3(p.x, 0, p.y)),
      largeRadius,
      smallRadius,
      sunPosition: new Vector3(sunOffsetRange(eccentricity), 0, 0),
    };
  }, [eccentricity, pointCount]);
}
