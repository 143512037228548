import React, {
  FC,
  ReactNode,
  createContext,
  useState,
  useEffect,
} from 'react';

export interface IBreakpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  '2xl': number;
}

export interface IScreenSize {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  '2xl': boolean;
}

export interface IScreenSizeContext {
  screenSize: IScreenSize;
}

export const ScreenSizeContext = createContext<IScreenSize>({} as IScreenSize);

export const ScreenSizeProvider: FC<{
  children: ReactNode;
  breakpoints: IBreakpoints;
}> = ({ children, breakpoints }) => {
  const [screenSize, setScreenSize] = useState<IScreenSize>({} as IScreenSize);

  useEffect(() => {
    const handleScreenSizes = () => {
      const w = window.innerWidth;

      setScreenSize({
        xs: w <= breakpoints.xs,
        sm: w <= breakpoints.sm,
        md: w <= breakpoints.md,
        lg: w <= breakpoints.lg,
        xl: w <= breakpoints.xl,
        '2xl': w > breakpoints.xl,
      });
    };

    handleScreenSizes();

    window.addEventListener('resize', handleScreenSizes);
    return () => window.removeEventListener('resize', handleScreenSizes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
