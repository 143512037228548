import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../providers/store/use-stores';
import { ExternalDataStatus } from '../../stores/external-data';
import { Chart } from './Chart';
import { ChartCheckbox } from './ChartCheckbox';
import { enumerate } from '../../utils';

export const ChartView = observer(() => {
  const {
    chartStore: {
      precession,
      obliquity,
      eccentricity,
      tempChange,
      chartData,
      isMultiselectEnabled,
      toggleParameter,
    },
  } = useStores();

  const legendProperties = [
    eccentricity.isDisplayed && 'Eccentricity',
    obliquity.isDisplayed && 'Obliquity',
    precession.isDisplayed && 'Precession',
  ].filter(Boolean) as string[];
  const titleProperties = [
    ...legendProperties,
    tempChange.isDisplayed && 'Temperature',
  ].filter(Boolean) as string[];
  const legendText = enumerate(legendProperties);
  const titleText = `${enumerate(titleProperties)} Over\xa0Time`;

  const lastCheckboxDisabled =
    legendProperties.length === 1 && isMultiselectEnabled;

  const chartCheckboxClasses =
    'mx-0 lg:mx-1 flex justify-center basis-1/2 md:basis-0';
  const lineLegendClasses =
    'flex flex-col justify-center items-center my-2 xs:my-0 basis-1/2 xs:basis-0';

  return (
    <div className="w-full h-full flex flex-col items-center">
      <div className="mx-2 my-3 w-full flex gap-0 md:gap-sm flex-wrap items-center justify-center">
        <ChartCheckbox
          label="Temperature"
          control={tempChange}
          onChange={(value) => {
            tempChange.setIsDisplayed(value);
          }}
          className={chartCheckboxClasses}
        />
        <div className="hidden md:block w-[2px] h-[70px] bg-blue-light opacity-60" />
        <ChartCheckbox
          label="Eccentricity"
          control={eccentricity}
          onChange={toggleParameter}
          isDisabled={eccentricity.isDisplayed && lastCheckboxDisabled}
          className={chartCheckboxClasses}
        />
        <ChartCheckbox
          label="Obliquity"
          control={obliquity}
          onChange={toggleParameter}
          isDisabled={obliquity.isDisplayed && lastCheckboxDisabled}
          className={chartCheckboxClasses}
        />
        <ChartCheckbox
          label="Precession"
          control={precession}
          onChange={toggleParameter}
          isDisabled={precession.isDisplayed && lastCheckboxDisabled}
          className={chartCheckboxClasses}
        />
      </div>
      <div className="relative flex-1 bg-white rounded-b-md h-fit w-full flex flex-col items-center">
        <h3 className="lg:w-3/4 w-full mx-4 mt-4 mb-2 lg:text-xl sm:text-lg text-sm text-blue-light font-semibold text-center">
          {titleText}
        </h3>
        <div className="h-full lg:w-3/4 md:w-[90%] w-[96%] p-sm text-gray-800">
          {chartData.status === ExternalDataStatus.loading ? (
            <div className="text-center">loading data</div>
          ) : chartData.status === ExternalDataStatus.error ? (
            <div className="text-center">failed to fetch data</div>
          ) : (
            <div className="w-full h-full flex flex-col items-center font-semibold relative md:text-md text-sm">
              <div className="relative flex-1 w-full min-h-[300px] max-h-[450px]">
                <Chart mergedLineLabel={legendText} />
                <h6 className="pointer-events-none select-none absolute rotate-[-90deg] left-[-115px] md:left-[-150px] top-[38%]">
                  Global Temperature Anomaly (C°)
                </h6>
                <h6 className="hidden md:block pointer-events-none select-none absolute rotate-90 right-[-120px] md:right-[-150px] top-[38%]">
                  Global Temperature Anomaly (C°)
                </h6>
                <div className="absolute w-[20px] h-[calc(100%-59px)] left-[40px] top-0 bg-gradient-to-b from-temperatureAnomaly-high to-temperatureAnomaly-low" />
              </div>
              <h6 className="pointer-events-none mt-2 select-none relative">
                Time (thousands of years ago)
              </h6>
            </div>
          )}
        </div>
        <div className="ml-md mb-md pt-md px-0 xs:px-md bg-gray-10 border border-gray-50 rounded-sm text-gray-800 flex justify-center items-center gap-0 xs:gap-lg text-sm self-start flex flex-wrap">
          {tempChange.isDisplayed && (
            <div className={lineLegendClasses}>
              <div className="rounded-full w-3/4 mb-[6px] border-b-2 border-chart-anomaly border-dashed w-[60px]" />
              <span className="whitespace-nowrap">Temperature</span>
            </div>
          )}
          <div className={lineLegendClasses}>
            <div className="rounded-full w-3/4 mb-[6px] border-b-[3px] border-chart-rest w-[60px]" />
            <span className="whitespace-nowrap">{legendText}</span>
          </div>
        </div>
      </div>
    </div>
  );
});
