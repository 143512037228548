import * as THREE from 'three';

export const shiftPosition = (
  p: THREE.Vector3,
  axis: 'x' | 'y' | 'z',
  value: number
) =>
  new THREE.Vector3(
    axis === 'x' ? p.x + value : p.x,
    axis === 'y' ? p.y + value : p.y,
    axis === 'z' ? p.z + value : p.z
  );
